import React from "react"

export default function Chinese() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
      <defs>
        <clipPath id="clip-path">
          <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
        </clipPath>
      </defs>
      <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
        <g id="china_1_" data-name="china (1)" transform="translate(1631 284.249)">
          <path id="Path_14333" data-name="Path 14333" d="M12,27.751H60.774v34H12Z" fill="#d60a2e"/>
          <g id="Group_2438" data-name="Group 2438" transform="translate(14.595 30.318)">
            <path id="Path_14334" data-name="Path 14334" d="M23.066,40.976l1.709,3.464L28.6,45l-2.766,2.7.653,3.807-3.419-1.8-3.419,1.8.653-3.807L17.534,45l3.822-.555Z" transform="translate(-17.534 -37.341)" fill="#fed953"/>
            <path id="Path_14335" data-name="Path 14335" d="M48.417,55.152l.683,1.384,1.527.222-1.105,1.078.261,1.521-1.366-.718-1.366.718.261-1.521-1.1-1.078,1.527-.222Z" transform="translate(-32.76 -44.869)" fill="#fed953"/>
            <path id="Path_14336" data-name="Path 14336" d="M47.825,42.248l1.248.908,1.455-.514-.478,1.468.938,1.225H49.446l-.876,1.271-.476-1.468-1.479-.44,1.249-.907Z" transform="translate(-32.976 -38.016)" fill="#fed953"/>
            <path id="Path_14337" data-name="Path 14337" d="M40.819,33.28,40.527,34.8l1.083,1.1-1.532.191-.711,1.37-.655-1.4-1.523-.253,1.127-1.055-.23-1.526,1.352.746Z" transform="translate(-27.972 -33.225)" fill="#fed953"/>
            <path id="Path_14338" data-name="Path 14338" d="M40.819,66.393l-.292,1.516,1.083,1.1-1.532.191-.711,1.37-.655-1.4-1.523-.253,1.127-1.055-.23-1.526,1.352.746Z" transform="translate(-27.972 -50.809)" fill="#fed953"/>
          </g>
        </g>
      </g>
    </svg>
  )
}