import React from "react";
import { FormattedMessage } from "react-intl";

export default function GameStartButton({onClick, ...props}) {

    return (
        <div {...props} className="game-start-button">
            <button onClick={onClick}>
                <FormattedMessage id="play_now" />
            </button>
        </div>
    )
}