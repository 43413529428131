import initialStore from "../initialStore";
import {
    SET_ALL_GAMES,
    UPDATE_GAME
} from "@actions/actionTypes";

export default function allGamesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_ALL_GAMES:
            return action.games;
        case UPDATE_GAME:
            return state.map(game => {
                if (game.id == action.game.id) {
                    return {
                        ...game,
                        ...action.game
                    }
                } else {
                    return game;
                }
            })
        default:
            return state;
    }
}