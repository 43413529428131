import initialStore from "../initialStore";
import {
    SET_CATEGORY
} from "@actions/actionTypes";

export default function selectedCategoryReducer(state = initialStore.selectedCategory, action) {
    switch (action.type) {
        case SET_CATEGORY:
            return action.category;
        default:
            return state;
    }
}