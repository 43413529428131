import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { TimePeriodsTypeNames, TimePeriodsType } from "@constants/Enums";
import PeriodHistoryLabel from "./PeriodHistoryLabel"
import {
    setHistoryPeriod
} from "@actions/historyActions";
import {
    closeHistoryPeriodPopup
} from "@actions/popupActions";
import classNames from "classnames";

function PeriodList({ periodList, historyPeriod, setHistoryPeriod, closeHistoryPeriodPopup }) {

    const selectPeriod = (period) => {
        setHistoryPeriod(period);
        closeHistoryPeriodPopup();
    }
    return (
        <>
            {
                TimePeriodsTypeNames.map((el, i) => {
                    return(
                        <div key={i}>
                            <div className={classNames("period_list", {selected: historyPeriod == TimePeriodsType[el]})}
                                onClick={() => selectPeriod(TimePeriodsType[el])}>
                                <span><FormattedMessage id={el}/></span>
                                <div className="checked_parent">
                                    {historyPeriod == TimePeriodsType[el] && <PeriodHistoryLabel/> || "" }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>

    )
}

function mapStateToProps(state) {
    return {
        historyPeriod: state.historyPeriod
    }
}

const MapStateToPropsParam = {
    setHistoryPeriod,
    closeHistoryPeriodPopup
}

export default connect(mapStateToProps, MapStateToPropsParam) (PeriodList)