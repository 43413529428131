//lang
export const SET_AVAILABLE_LANGUAGES = "SET_AVAILABLE_LANGUAGES";
export const REQUEST_AVAILABLE_LANGUAGES = "REQUEST_AVAILABLE_LANGUAGES";
export const SET_SELECTED_LANGUAGE_MESSAGE = "SET_SELECTED_LANGUAGE_MESSAGE";
export const REQUEST_SELECTED_LANGUAGE_MESSAGE = "REQUEST_SELECTED_LANGUAGE_MESSAGE";

//socket
export const CONNECT_TO_SOCKET = "CONNECT_TO_SOCKET";
export const UPDATE_DATA = "update";
export const SET_SOCKET_CONECTIONS = "SET_SOCKET_CONECTIONS";

export const CATEGORY_UPDATE = "100";
export const GAME_UPDATE = "101";
export const GAME_ADD = "102";
export const GAME_REMOVE = "103";
export const BALANCE_UPDATE = "104";
export const FIRST_INFO = "info";

export const ADD_GAME_TO_SELECTED_CATEGORY = "ADD_GAME_TO_SELECTED_CATEGORY";
export const REMOVE_GAME_TO_SELECTED_CATEGORY = "REMOVE_GAME_TO_SELECTED_CATEGORY";
export const UPDATE_GAME_TO_SELECTED_CATEGORY = "UPDATE_GAME_TO_SELECTED_CATEGORY";

export const SET_BALANCE = "SET_BALANCE";

//error
export const SET_ALERT_MSG = "SET_ALERT_MSG";
export const RESET_ALERT_MSG = "RESET_ALERT_MSG";

//settings
export const SET_SETTINGS = "SET_SETTINGS";

// category
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES";

// games
export const SET_ALL_GAMES = "SET_ALL_GAMES";
export const REQUEST_ALL_GAMES = "REQUEST_ALL_GAMES";
export const SET_GAMES = "SET_GAMES";

export const REMOVE_GAME = "REMOVE_GAME";
export const ADD_GAME = "ADD_GAME";
export const UPDATE_GAME = "UPDATE_GAME";

//global info
export const SET_TABLES_INFO = "SET_TABLES_INFO";
export const REQUEST_TABLES_INFO = "REQUEST_TABLES_INFO";
export const SET_HISTORY = "SET_HISTORY";
export const REQUEST_HISTORY = "REQUEST_HISTORY";

//popups
export const SET_PLAYER_HOSTORY_POPUP = "SET_PLAYER_HOSTORY_POPUP";
export const SET_LANGUAGES_POPUP = "SET_LANGUAGES_POPUP";
export const SET_MENU_POPUP = "SET_MENU_POPUP";
export const SET_HISTORY_POPUP = "SET_HISTORY_POPUP"

// user
export const SET_USER = "SET_USER";
export const REQUEST_USER = "REQUEST_USER";


export const REQUEST_GAME_START = "REQUEST_GAME_START";
export const SET_GAME_START = "SET_GAME_START";

export const OPEN_GAME = "OPEN_GAME";
export const CLOSE_GAME = "CLOSE_GAME";

// player history
export const SET_HISTORY_PERIOD = "SET_HISTORY_PERIOD";

