import React, { useState } from "react";
import { useEffect } from "react";

function Imgs({icon, iconHover, iconPressed}) {

    return (
        <div className="imgs-block" >
            { icon && 
                <>
                    <img src={icon}/>
                    <img src={iconHover}/>
                    <img src={iconPressed}/>
                </>
            }
        </div>
    )
}

export default Imgs;