import {
    CONNECT_TO_SOCKET
} from "@actions/actionTypes";

export default function socketMiddleware(socket) {
    return ({dispatch}) => next => (action) => {
        if (typeof action === 'function') return next(action);

        if (action.type === CONNECT_TO_SOCKET) {
            socket.connect(action.token);
            return;
        }
        
        const {eventOn, eventEmit, handle, emitData, keys} = action;
        
        if (eventOn && typeof eventOn === "string") {
            socket.off(eventOn);
            return socket.on(eventOn, handle);
        } else if (eventEmit && typeof eventEmit === "string") {
            return socket.emit(eventEmit, emitData);
        }
        
        return next(action);
    };
}
