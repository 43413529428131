import React from "react";

function PeopleIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 32">
  <defs>
    <filter id="PeopleIcon" x="0" y="0"  filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2" result="blur"/>
      <feFlood/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_1892" data-name="Group 1892" transform="translate(-270.999 -346)">
    <rect id="Rectangle_1115" data-name="Rectangle 1115" width="20" height="20" transform="translate(276 350)" fill="none"/>
    <g transform="matrix(1, 0, 0, 1, 271, 346)" filter="url(#PeopleIcon)">
      <path id="Exclusion_2-2" data-name="Exclusion 2" d="M8.978,20a10.455,10.455,0,0,1-3.263-.519,10.3,10.3,0,0,1-2.938-1.522,13.738,13.738,0,0,1-1.049-.907A8.639,8.639,0,0,1,.679,15.873a9.16,9.16,0,0,1-.616-.907A.588.588,0,0,1,0,14.751a.4.4,0,0,1,.04-.215,5.587,5.587,0,0,1,.524-.952,6.449,6.449,0,0,1,4.491-2.812.745.745,0,0,1,.145-.014.882.882,0,0,1,.516.173,5.462,5.462,0,0,0,1.556.8,5.674,5.674,0,0,0,3.454,0,5.462,5.462,0,0,0,1.556-.8.921.921,0,0,1,.549-.164c.037,0,.075,0,.112.006a6.4,6.4,0,0,1,2.536.921,6.554,6.554,0,0,1,1.067.836,6.727,6.727,0,0,1,.888,1.055,4.787,4.787,0,0,1,.524.952.356.356,0,0,1,.034.215.446.446,0,0,1-.1.215,7.878,7.878,0,0,1-.616.907,10.507,10.507,0,0,1-1.049,1.179,9.976,9.976,0,0,1-1.049.907,10.3,10.3,0,0,1-2.938,1.522A10.455,10.455,0,0,1,8.978,20ZM9,10.386A5.239,5.239,0,0,1,4.192,7.209a5.138,5.138,0,0,1,0-4.033,5.2,5.2,0,0,1,.483-.882A5.261,5.261,0,0,1,6.973.41a5.214,5.214,0,0,1,4.942.48,5.269,5.269,0,0,1,1.412,1.4,5.2,5.2,0,0,1,.483.882,5.138,5.138,0,0,1,0,4.033,5.2,5.2,0,0,1-.483.882,5.261,5.261,0,0,1-2.3,1.885A5.2,5.2,0,0,1,9,10.386Z" transform="translate(6 4)" fill="#fff"/>
    </g>
  </g>
</svg>

    )
}

export default PeopleIcon