import React from "react";
function Russian() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
            <defs>
                <clipPath id="clip-path">
                    <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1" />
                </clipPath>
            </defs>
            <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
                <g id="russia_1_" data-name="russia (1)" transform="translate(1643 226.67)">
                    <path id="Path_14330" data-name="Path 14330" d="M0,85.33v32H32v-32Z" fill="#f0f0f0" />
                    <rect id="Rectangle_1863" data-name="Rectangle 1863" width="32" height="32" transform="translate(0 85.33)" fill="#0052b4" />
                    <rect id="Rectangle_1864" data-name="Rectangle 1864" width="32" height="10.666" transform="translate(0 85.33)" fill="#f0f0f0" />
                    <rect id="Rectangle_1865" data-name="Rectangle 1865" width="32" height="10.666" transform="translate(0 106.663)" fill="#d80027" />
                </g>
            </g>
        </svg>
    )
}


export default Russian