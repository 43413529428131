export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function floatNumberWithSpaces(x) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function abbrNum(number, decPlaces = 1) {
    let sum = number;
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate sum abbreviations
    var abbrev = ["k", "m", "b", "t"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10, (i + 1) * 3);

        // If the sum is bigger or equal do the abbreviation
        if(size <= sum) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            sum = Math.round(sum * decPlaces / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            if((sum === 1000) && (i < abbrev.length - 1)) {
                sum = 1;
                i++;
            }

            // Add the letter for the abbreviation
            sum += abbrev[i];

            // We are done... stop
            break;
        }
    }

    return sum;
}