import React from "react";
import HeaderActionIteams from "./HeaderActionIteams";

function HeaderActions({actionList}){
    return(
        <ul className="headerActions">
            {
                actionList.map((item, index) => {
                    return <HeaderActionIteams key={index}
                                                name={item.name}
                                                action={item.action}
                                                icon={item.icon}
                                                className={item.className}/>
                })
            }
        </ul>
    )
}

export default HeaderActions