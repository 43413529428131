
import React from "react"
export default function Arab(){
    return(
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="Layer_49" data-name="Layer 49" transform="translate(1635 298)">
      <path id="Path_14339" data-name="Path 14339" d="M0,0H49.778V10.667H0Z" transform="translate(53.778 46) rotate(180)"/>
      <path id="Path_14340" data-name="Path 14340" d="M0,0H49.778V10.667H0Z" transform="translate(53.778 35.333) rotate(180)" fill="#fff"/>
      <path id="Path_14341" data-name="Path 14341" d="M0,0H49.778V10.667H0Z" transform="translate(53.778 24.667) rotate(180)" fill="#090"/>
      <path id="Path_14342" data-name="Path 14342" d="M0,0H13.778V32H0Z" transform="translate(17.778 46) rotate(180)" fill="red"/>
    </g>
  </g>
</svg>
    )
}
