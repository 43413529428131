import {
    SET_PLAYER_HOSTORY_POPUP,
    SET_LANGUAGES_POPUP,
    SET_MENU_POPUP,
    SET_HISTORY_POPUP
} from "@actions/actionTypes";


export function closePlayerHistoryPopup() {
    return dispatch => {
        dispatch({type: SET_PLAYER_HOSTORY_POPUP, isOpen: false});
    }
}

export function openPlayerHistoryPopup() {
    return dispatch => {
        dispatch({type: SET_PLAYER_HOSTORY_POPUP, isOpen: true});
    }
}


export function closeLanguagesPopup() {
    return dispatch => {
        dispatch({type: SET_LANGUAGES_POPUP, isOpen: false});
    }
}

export function openLanguagesPopup() {
    return dispatch => {
        dispatch({type: SET_LANGUAGES_POPUP, isOpen: true});
    }
}

export function closeMenuPopup() {
    return dispatch => {
        dispatch({type: SET_MENU_POPUP, isOpen: false});
    }
}

export function openMenuPopup() {
    return dispatch => {
        dispatch({type: SET_MENU_POPUP, isOpen: true});
    }
}

export function closeHistoryPeriodPopup() {
    return dispatch => {
        dispatch({type: SET_HISTORY_POPUP, isOpen: false})
    }
}

export function openHistoryPeriodPopup() {
    return dispatch => {
        dispatch({type: SET_HISTORY_POPUP, isOpen: true})
    }
}