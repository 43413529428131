import classNames from "classnames";
import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl";
import CloseIcon from "@resource/CloseIcon";

export default function Popup({children, title, className, onClose}) {

    const close = () => {
        onClose && onClose();
    }

    return (
        <div className="popup-wraper" onClick={close}>
            <div className={classNames("popup", className)} onClick={e => e.stopPropagation()}>
                <div className="popup-header">
                    <div className="close-icon" onClick={close}>
                        <CloseIcon />
                    </div>
                    <span><FormattedMessage id={title} /></span>
                </div>
                {
                    children
                }
            </div>
        </div>
    );
}