

import React from "react"
export default function Indian(){
    return(
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="india_1_" data-name="india (1)" transform="translate(1635 226.663)">
      <rect id="Rectangle_1870" data-name="Rectangle 1870" width="48.001" height="32" transform="translate(0 85.337)" fill="#f0f0f0"/>
      <rect id="Rectangle_1871" data-name="Rectangle 1871" width="48.001" height="10.667" transform="translate(0 85.337)" fill="#ff9811"/>
      <rect id="Rectangle_1872" data-name="Rectangle 1872" width="48.001" height="10.667" transform="translate(0 106.67)" fill="#6da544"/>
      <circle id="Ellipse_1753" data-name="Ellipse 1753" cx="4.115" cy="4.115" r="4.115" transform="translate(19.885 97.222)" fill="#0052b4"/>
      <circle id="Ellipse_1754" data-name="Ellipse 1754" cx="2.572" cy="2.572" r="2.572" transform="translate(21.429 98.765)" fill="#f0f0f0"/>
      <path id="Path_14346" data-name="Path 14346" d="M229.43,222.146l.794,1.8,1.955-.213-1.162,1.587,1.162,1.587-1.955-.213-.794,1.8-.794-1.8-1.955.213,1.162-1.587-1.162-1.587,1.955.213Z" transform="translate(-205.429 -123.983)" fill="#0052b4"/>
    </g>
  </g>
</svg>
    )
}