import React from "react"
function Exclusion() {
  return (
    <>
      <svg className="normal-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
        <defs>
          <linearGradient id="linear-gradient-Exclusion-normal-state" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f2f2f2"/>
            <stop offset="1" stopColor="#bfbfbf"/>
          </linearGradient>
          <filter id="Exclusion_1_normal_state" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="icon_filled_application_appstore_clip_group_" transform="translate(6 4)">
          <rect id="icon_filled_application_appstore_style_" width="24" height="24" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, -6, -4)" filter="url(#Exclusion_1_normal_state)">
            <path id="Exclusion_1-2" d="M-1819.312-82h-5.921a.312.312,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.312.312,0,0,1-1819.312-82Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.313.313,0,0,1-1828.04-82Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.313.313,0,0,1-1836.767-82Zm17.454-8.727h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.312.312,0,0,1,.312.312v5.921A.312.312,0,0,1-1819.312-90.727Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1828.04-90.727Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.921a.313.313,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1836.767-90.727Zm17.454-8.727h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.312.312,0,0,1,.312.312v5.921A.312.312,0,0,1-1819.312-99.455Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1828.04-99.455Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.921a.313.313,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1836.767-99.455Z" transform="translate(1849 110)" fill="url(#linear-gradient-Exclusion-normal-state)"/>
          </g>
        </g>
      </svg>
      <svg className="hover-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
        <defs>
          <filter id="Exclusion_1_hover_state" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="icon_filled_application_appstore_clip_group_" transform="translate(6 4)">
          <rect id="icon_filled_application_appstore_style_" width="24" height="24" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, -6, -4)" filter="url(#Exclusion_1_hover_state)">
            <path id="Exclusion_1-2" d="M-1819.312-82h-5.921a.312.312,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.312.312,0,0,1-1819.312-82Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.313.313,0,0,1-1828.04-82Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.313.313,0,0,1-1836.767-82Zm17.454-8.727h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.312.312,0,0,1,.312.312v5.921A.312.312,0,0,1-1819.312-90.727Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1828.04-90.727Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.921a.313.313,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1836.767-90.727Zm17.454-8.727h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.312.312,0,0,1,.312.312v5.921A.312.312,0,0,1-1819.312-99.455Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1828.04-99.455Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.921a.313.313,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1836.767-99.455Z" transform="translate(1849 110)" fill="#ffd35a"/>
          </g>
        </g>
      </svg>
      <svg className="pressed-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
        <defs>
          <filter id="Exclusion_1_pressed_state" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="icon_filled_application_appstore_clip_group_" transform="translate(6 4)">
          <rect id="icon_filled_application_appstore_style_" width="24" height="24" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, -6, -4)" filter="url(#Exclusion_1_pressed_state)">
            <path id="Exclusion_1-2" d="M-1819.312-82h-5.921a.312.312,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.312.312,0,0,1-1819.312-82Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.313.313,0,0,1-1828.04-82Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.922a.312.312,0,0,1,.312-.311h5.921a.312.312,0,0,1,.312.311v5.922A.313.313,0,0,1-1836.767-82Zm17.454-8.727h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.312.312,0,0,1,.312.312v5.921A.312.312,0,0,1-1819.312-90.727Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1828.04-90.727Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.921a.313.313,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1836.767-90.727Zm17.454-8.727h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.312.312,0,0,1,.312.312v5.921A.312.312,0,0,1-1819.312-99.455Zm-8.727,0h-5.921a.312.312,0,0,1-.312-.312v-5.921a.312.312,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1828.04-99.455Zm-8.727,0h-5.921a.313.313,0,0,1-.312-.312v-5.921a.313.313,0,0,1,.312-.312h5.921a.313.313,0,0,1,.312.312v5.921A.313.313,0,0,1-1836.767-99.455Z" transform="translate(1849 110)" fill="#fc0"/>
          </g>
        </g>
      </svg>
    </>
  )
}

export default Exclusion
