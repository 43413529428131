import React from "react";


function HomeIcon(){
    return(
      <>
        <svg className="normal-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
          <defs>
            <linearGradient id="linear-gradient-HomeIcon-normal-state" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#f2f2f2"/>
              <stop offset="1" stopColor="#bfbfbf"/>
            </linearGradient>
            <filter id="VectorHomeIconNormalState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1872" data-name="Group 1872" transform="translate(-1299 -20)">
            <rect id="Rectangle_1095" data-name="Rectangle 1095" width="24" height="24" transform="translate(1305 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1299, 20)" filter="url(#VectorHomeIconNormalState)">
              <path id="Vector-2" data-name="Vector" d="M21.158,24H2.843V14.871H1.718A1.763,1.763,0,0,1,0,13.1a1.88,1.88,0,0,1,.126-.7A1.785,1.785,0,0,1,.5,11.807L11.413.256a.8.8,0,0,1,1.175,0l.685.727L23.5,11.807a1.835,1.835,0,0,1,.5,1.267,1.859,1.859,0,0,1-.127.688,1.783,1.783,0,0,1-.367.582,1.643,1.643,0,0,1-1.2.526H21.158V24ZM9.882,14.47h4.236a1.093,1.093,0,0,1,1.059,1.122v6.39h4.074V12.852h2.543L12.614,3.138,12,2.491,2.208,12.852H4.749v9.129H8.824v-6.39A1.093,1.093,0,0,1,9.882,14.47Zm.635,1.794h0v5.717h2.965V16.264H10.517Z" transform="translate(6 4)" fill="url(#linear-gradient-HomeIcon-normal-state)"/>
            </g>
          </g>
        </svg>
        <svg className="hover-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
          <defs>
            <filter id="VectorHomeIconHoverState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1872" data-name="Group 1872" transform="translate(-1299 -20)">
            <rect id="Rectangle_1095" data-name="Rectangle 1095" width="24" height="24" transform="translate(1305 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1299, 20)" filter="url(#VectorHomeIconHoverState)">
              <path id="Vector-2" data-name="Vector" d="M21.158,24H2.843V14.871H1.718A1.763,1.763,0,0,1,0,13.1a1.88,1.88,0,0,1,.126-.7A1.785,1.785,0,0,1,.5,11.807L11.413.256a.8.8,0,0,1,1.175,0l.685.727L23.5,11.807a1.835,1.835,0,0,1,.5,1.267,1.859,1.859,0,0,1-.127.688,1.783,1.783,0,0,1-.367.582,1.643,1.643,0,0,1-1.2.526H21.158V24ZM9.882,14.47h4.236a1.093,1.093,0,0,1,1.059,1.122v6.39h4.074V12.852h2.543L12.614,3.138,12,2.491,2.208,12.852H4.749v9.129H8.824v-6.39A1.093,1.093,0,0,1,9.882,14.47Zm.635,1.794h0v5.717h2.965V16.264H10.517Z" transform="translate(6 4)" fill="#ffd35a"/>
            </g>
          </g>
        </svg>
        <svg className="pressed-state"  xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36" height="36" viewBox="0 0 36 36">
          <defs>
            <filter id="VectorHomeIconPressedState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1872" data-name="Group 1872" transform="translate(-1299 -20)">
            <rect id="Rectangle_1095" data-name="Rectangle 1095" width="24" height="24" transform="translate(1305 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1299, 20)" filter="url(#VectorHomeIconPressedState)">
              <path id="Vector-2" data-name="Vector" d="M21.158,24H2.843V14.871H1.718A1.763,1.763,0,0,1,0,13.1a1.88,1.88,0,0,1,.126-.7A1.785,1.785,0,0,1,.5,11.807L11.413.256a.8.8,0,0,1,1.175,0l.685.727L23.5,11.807a1.835,1.835,0,0,1,.5,1.267,1.859,1.859,0,0,1-.127.688,1.783,1.783,0,0,1-.367.582,1.643,1.643,0,0,1-1.2.526H21.158V24ZM9.882,14.47h4.236a1.093,1.093,0,0,1,1.059,1.122v6.39h4.074V12.852h2.543L12.614,3.138,12,2.491,2.208,12.852H4.749v9.129H8.824v-6.39A1.093,1.093,0,0,1,9.882,14.47Zm.635,1.794h0v5.717h2.965V16.264H10.517Z" transform="translate(6 4)" fill="#fc0"/>
            </g>
          </g>
        </svg>
      </>
    )
}
export default HomeIcon