import en from "./local/en.json"
import ru from "./local/ru.json"
import ar from "./local/ar.json"
import bg from "./local/bg.json"
import es from "./local/es.json"
import hi from "./local/hi.json"
import it from "./local/it.json"
import pt from "./local/pt.json"
import tr from "./local/tr.json"
import zh from "./local/zh.json"
import {merge, cloneDeep} from "lodash"

const lang = {
    en,
    ru: merge(cloneDeep(en), ru),
    ar: merge(cloneDeep(en), ar),
    bg: merge(cloneDeep(en), bg),
    es: merge(cloneDeep(en), es),
    hi: merge(cloneDeep(en), hi),
    it: merge(cloneDeep(en), it),
    pt: merge(cloneDeep(en), pt),
    tr: merge(cloneDeep(en), tr),
    zh: merge(cloneDeep(en), zh)
}

export default lang;