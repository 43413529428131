import initialStore from "../initialStore";
import {
    SET_PLAYER_HOSTORY_POPUP,
    SET_LANGUAGES_POPUP,
    SET_MENU_POPUP,
    SET_HISTORY_POPUP
} from "@actions/actionTypes";

export default function popupsReducer(state = initialStore.popups, action) {
    switch (action.type) {
        case SET_PLAYER_HOSTORY_POPUP:
            return {
                ...initialStore.popups,
                isShowPlayerHostory: action.isOpen
            };
        case SET_LANGUAGES_POPUP:
            return {
                ...initialStore.popups,
                isShowLanguages: action.isOpen
            };
        case SET_MENU_POPUP:
            return {
                ...initialStore.popups,
                isShowMenu: action.isOpen
            };
        case SET_HISTORY_POPUP:
            return {
                ...initialStore.popups,
                isShowHistory: action.isOpen,
                isShowPlayerHostory: state.isShowPlayerHostory || action.isOpen
            }
        default:
            return state;
    }
}