import initialStore from "../initialStore";
import {
    SET_HISTORY
} from "@actions/actionTypes";

export default function playerHistoryReducer(state = initialStore.playerHistory, action) {
    switch (action.type) {
        case SET_HISTORY:
            return action.playerHistory;
        default:
            return state;
    }
}