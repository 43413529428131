import initialStore from "../initialStore";
import {
    SET_CATEGORIES
} from "@actions/actionTypes";

export default function categoriesReducer(state = initialStore.categories, action) {
    switch (action.type) {
        case SET_CATEGORIES:
            return action.categories;
        default:
            return state;
    }
}