import classNames from "classnames";
import React from "react";

function HeaderActionIteam({name, icon, action, className, ...restProps}){
    return(
        <li onClick={action} {...restProps} className={classNames("headerActions-item", className)}>
            <div className="icon">{icon}</div>
            <div className="name">{name}</div>

        </li>
    )
}

export default HeaderActionIteam