import React from "react";
import {connect} from "react-redux";
import { FormattedMessage } from "react-intl";
import Russian from "../resource/Russian";
import English from "../resource/English";
import Popup from "./Popup";
import CheckedIcon from "../resource/CheckedIcon";
import Spanish from "../resource/Spanish";
import Turkish from "../resource/Turkish";
import Chinese from "../resource/Chinese";
import Bulgarian from "../resource/Bulgarian"
import Arab from "../resource/Arab"
import Italian from "../resource/Italian"
import Portuguese from "../resource/Portuguese"
import Indian from "../resource/Indian"
import {closeLanguagesPopup} from "@actions/popupActions";
import classNames from "classnames";
import {setSettings} from "@actions/settingsActions";
import {fetchCategoriesAndAllGames} from "@actions/categoryActions";


function LanguagesPopup({settings, closeLanguagesPopup, setSettings, fetchCategoriesAndAllGames}) {
    const languagesArray = [
        {
            icon: <English />,
            language: <FormattedMessage id="english" />,
            checked: <CheckedIcon />,
            key: "en"
        },
        {
            icon: <Spanish />,
            language: <FormattedMessage id="spanish" />,
            checked: <CheckedIcon />,
            key: "es"
        },
        {
            icon: <Russian />,
            language: <FormattedMessage id="russian" />,
            checked: <CheckedIcon />,
            key: "ru"
        },
        {
            icon: <Turkish />,
            language: <FormattedMessage id="turkish" />,
            checked: <CheckedIcon />,
            key: "tr"
        },
        {
            icon: <Chinese />,
            language: <FormattedMessage id="chinese" />,
            checked: <CheckedIcon />,
            key: "zh"
        },
        {
            icon: <Bulgarian />,
            language: <FormattedMessage id="bulgarian" />,
            checked: <CheckedIcon />,
            key: "bg"
        },
        {
            icon: <Arab />,
            language: <FormattedMessage id="arab" />,
            checked: <CheckedIcon />,
            key: "ar"
        },
        {
            icon: <Italian />,
            language: <FormattedMessage id="italian" />,
            checked: <CheckedIcon />,
            key: "it"
        },

        {
            icon: <Portuguese />,
            language: <FormattedMessage id="portuguese" />,
            checked: <CheckedIcon />,
            key: "pt"
        },
        {
            icon: <Indian />,
            language: <FormattedMessage id="indian" />,
            checked: <CheckedIcon />,
            key: "hi"
        }
    ]

    const selectLang = (lang) => {
        setSettings({lang});
        fetchCategoriesAndAllGames(lang);
        closeLanguagesPopup();
    }

    return (
        <Popup title="languages" className="languages-popup" onClose={closeLanguagesPopup}>
            <div className="languages-list">
                {
                   languagesArray.map((el) => (
                        <div className={classNames("languages-line", {'is-selected': el.key === settings.lang})}
                            key={el.key}
                            onClick={() => selectLang(el.key)}>
                            <div className="languages-icon">
                                {el.icon} 
                            </div>
                            <span>{el.language}</span>
                            
                            <div className="checked-icon">{el.checked}</div>
                        </div>
                        )
                    )
                }
            </div>
        </Popup>
    )
}

function mapStateToProps(state) {
    return {
        settings: state.settings
    }
}

const mapDispatchToProps = {
    closeLanguagesPopup,
    setSettings,
    fetchCategoriesAndAllGames
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesPopup);