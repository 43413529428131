import {
    SET_CATEGORY,
    SET_GAMES,
    REQUEST_CATEGORIES,
    SET_TABLES_INFO
} from "@actions/actionTypes";


export function setCategory(category) {
    return (dispatch, getState) => {
        const {allGames} = getState();
        dispatch({type: SET_CATEGORY, category});
        const games = allGames.filter(game => game.category === category.id)
        dispatch({type: SET_GAMES, games});
        dispatch({
            type: SET_TABLES_INFO,
            data: {
                countOfTables: games.length,
                countOfPlayers: games.reduce((a, b) => a + b.activePlayers, 0)
            }
        })
    }
}

export function fetchCategoriesAndAllGames(lang) {
    return dispatch => {
        dispatch({type: REQUEST_CATEGORIES, lang});
    }
}