import initialStore from "../initialStore";
import {
    SET_GAME_START,
    SET_SOCKET_CONECTIONS
} from "@actions/actionTypes";

export default function globalInfoReducer(state = initialStore.globalInfo, action) {
    switch (action.type) {
        case SET_SOCKET_CONECTIONS:
            return {...state, isConnectedSocket: action.isConected};
        case SET_GAME_START:
            return {...state, session: action.session};
        default:
            return state;
    }
}