import {call, put, takeLatest} from "redux-saga/effects";
import {GetResource} from "../../utils/HttpUtil";
import {
    SET_ALL_GAMES,
    REQUEST_ALL_GAMES
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";

function* fetchGames() {
    const games = yield call(GetResource, `/Games`);
    yield put({type: SET_ALL_GAMES, games});
}

export default function* gamesSaga() {
    yield takeLatest(REQUEST_ALL_GAMES, safe(onError, fetchGames));
}