import React, { Fragment, useEffect } from 'react';
import {connect} from "react-redux";
import Header from './layouts/Header';
import NavBar from './components/navBar/NavBar';
import { IntlProvider } from 'react-intl'
import Main from './components/main/Main';
import {DefaultLanguage} from "./constants/Enums";
import "@assets/scss/main.scss";
import {
  subscribeForCategoryUpdates,
  subscribeForGameUpdates,
  subscribeForFirstInfo,
  subscribeForBalanceUpdate,
  subscribeForGameRemove,
  subscribeForGameAdd
} from "@actions/socketActions";
import {fetchCategoriesAndAllGames} from "@actions/categoryActions";
import GameIframe from './components/GameIframe/GameIframe';
import { gameStart } from "@actions/gameControlActions";
import AlertMessages from "@components/alertMessage";


function App({
  translations,
  lang,
  user,
  selectedGame,
  gameStart,
  subscribeForCategoryUpdates,
  subscribeForGameUpdates,
  fetchCategoriesAndAllGames,
  subscribeForFirstInfo,
  subscribeForBalanceUpdate,
  subscribeForGameRemove,
  subscribeForGameAdd,
  isConnectedSocket}) {

    useEffect(() => {
      gameStart();
    }, [])

  useEffect(() => {
    if (isConnectedSocket) {
      subscribeForFirstInfo();
      subscribeForCategoryUpdates();
      subscribeForGameUpdates();
      subscribeForGameRemove();
      subscribeForBalanceUpdate();
      subscribeForGameAdd()
    }
  }, [isConnectedSocket])

  useEffect(() => {
    if (user.language) {
      fetchCategoriesAndAllGames(user.language);
    }
  }, [user])

  return (
    <IntlProvider messages={translations[lang]} locale={lang} defaultLocale={DefaultLanguage}>
      <div className="app">
        <AlertMessages />
        <div className="lobby-body">
          <div className='app-bg'></div>
          <div className='app-body'>
            <Header/>
            <NavBar/>
            <Main/>
          </div>
        </div>
        <GameIframe />
      </div>
    </IntlProvider>
  );
}


function mapStateToProps(state) {
  return {
    lang: state.settings.lang,
    isConnectedSocket: state.globalInfo.isConnectedSocket,
    user: state.user
  }
}

const mapDispatchToProps = {
  subscribeForCategoryUpdates,
  subscribeForGameUpdates,
  fetchCategoriesAndAllGames,
  subscribeForFirstInfo,
  subscribeForBalanceUpdate,
  subscribeForGameRemove,
  subscribeForGameAdd,
  gameStart
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
