import {applyMiddleware, compose} from "redux";
import rootReducer from "./reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga/index';
import { configureStore } from '@reduxjs/toolkit';
import SocketClient from "../utils/SocketClient";
import socketMiddleware from './middlewares/socketMiddleware';
import {
    SET_SOCKET_CONECTIONS,
    SET_ALERT_MSG
} from "./actions/actionTypes";

const sagaMiddleware = createSagaMiddleware();

const isDev = process.env.NODE_ENV === "development" || process.env.NODE_ENV === "env";

export default function configureStores(initialState) {
    const composeEnhancers =
    (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) || compose; // add support for Redux dev tools

    const socket = SocketClient();

    const middlewares = [
        sagaMiddleware,
        socketMiddleware(socket)
    ];
    if (isDev) {
        middlewares.splice(1, 0, reduxImmutableStateInvariant());
    }

    const store = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}).concat(middlewares),
        preloadedState: initialState
    });

    socket.on('connect', function() {
        store.dispatch({type: SET_SOCKET_CONECTIONS, isConected: true});
        console.log("connect")
    });

    socket.on('error', function(error) {
        store.dispatch({type: SET_ALERT_MSG, data: {type: 'error', messages: `error_${error.code}`}});
        console.error(error)
    });

    socket.on('disconnect', function() {
        store.dispatch({type: SET_SOCKET_CONECTIONS, isConected: false});
        console.log("disconnect")
    });

    sagaMiddleware.run(rootSaga);

    return store;
}
