import React from "react";
function CloseIcon() {
  return (
    <>
      <svg className="normal-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 26.222 28">
        <defs>
          <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f2f2f2"/>
            <stop offset="1" stopColor="#bfbfbf"/>
          </linearGradient>
          <filter id="Vector" x="0" y="0" width="26.222" height="28" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="Group_2739" transform="translate(-965 -304)">
          <rect id="Rectangle_1855" width="16" height="16" transform="translate(970 308)" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, 965, 304)" filter="url(#Vector)">
            <path id="Vector-2" d="M8.284,8l5.9-7.679A.2.2,0,0,0,14.042,0H12.25a.354.354,0,0,0-.276.14L7.111,6.474,2.248.14A.347.347,0,0,0,1.972,0H.18A.2.2,0,0,0,.043.321L5.939,8l-5.9,7.678a.2.2,0,0,0-.041.1.211.211,0,0,0,.015.109.191.191,0,0,0,.066.082.168.168,0,0,0,.1.03H1.972a.354.354,0,0,0,.276-.14L7.111,9.526l4.863,6.334a.348.348,0,0,0,.276.14h1.792a.2.2,0,0,0,.137-.322Z" transform="translate(6 4)" fill="url(#linear-gradient)"/>
          </g>
        </g>
      </svg>
      <svg className="hover-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="26.222" height="28" viewBox="0 0 26.222 28">
        <defs>
          <filter id="Vector" x="0" y="0" width="26.222" height="28" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="Group_2878" transform="translate(-965 -304)">
          <rect id="Rectangle_1855" width="16" height="16" transform="translate(970 308)" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, 965, 304)" filter="url(#Vector)">
            <path id="Vector-2" d="M8.284,8l5.9-7.679A.2.2,0,0,0,14.042,0H12.25a.354.354,0,0,0-.276.14L7.111,6.474,2.248.14A.347.347,0,0,0,1.972,0H.18A.2.2,0,0,0,.043.321L5.939,8l-5.9,7.678a.2.2,0,0,0-.041.1.211.211,0,0,0,.015.109.191.191,0,0,0,.066.082.168.168,0,0,0,.1.03H1.972a.354.354,0,0,0,.276-.14L7.111,9.526l4.863,6.334a.348.348,0,0,0,.276.14h1.792a.2.2,0,0,0,.137-.322Z" transform="translate(6 4)" fill="#ffd35a"/>
          </g>
        </g>
      </svg>
      <svg className="pressed-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 26.222 28">
        <defs>
          <filter id="Vector" x="0" y="0" width="26.222" height="28" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="Group_2882" transform="translate(-965 -304)">
          <rect id="Rectangle_1855" width="16" height="16" transform="translate(970 308)" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, 965, 304)" filter="url(#Vector)">
            <path id="Vector-2" d="M8.284,8l5.9-7.679A.2.2,0,0,0,14.042,0H12.25a.354.354,0,0,0-.276.14L7.111,6.474,2.248.14A.347.347,0,0,0,1.972,0H.18A.2.2,0,0,0,.043.321L5.939,8l-5.9,7.678a.2.2,0,0,0-.041.1.211.211,0,0,0,.015.109.191.191,0,0,0,.066.082.168.168,0,0,0,.1.03H1.972a.354.354,0,0,0,.276-.14L7.111,9.526l4.863,6.334a.348.348,0,0,0,.276.14h1.792a.2.2,0,0,0,.137-.322Z" transform="translate(6 4)" fill="#fc0"/>
          </g>
        </g>
      </svg>
    </>
  )
}

export default CloseIcon