import React from "react";

function Vector() {
  return (
    <>
      <svg className="normal-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
        <defs>
          <linearGradient id="linear-gradient-Vector-normal-state" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#f2f2f2"/>
            <stop offset="1" stopColor="#bfbfbf"/>
          </linearGradient>
          <filter id="VectorNormalState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="icon_filled_application_appstore_clip_group_" transform="translate(6 4)">
          <rect id="icon_filled_application_appstore_style_" width="24" height="24" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, -6, -4)" filter="url(#VectorNormalState)">
            <path id="Vector-2" d="M23.478,24H13.565a.522.522,0,0,1-.521-.522V13.565a.521.521,0,0,1,.521-.521h9.913a.522.522,0,0,1,.522.521v9.913A.523.523,0,0,1,23.478,24ZM10.434,24H.522A.523.523,0,0,1,0,23.478V13.565a.522.522,0,0,1,.522-.521h9.912a.522.522,0,0,1,.522.521v9.913A.523.523,0,0,1,10.434,24ZM23.478,10.956H13.565a.522.522,0,0,1-.521-.522V.522A.522.522,0,0,1,13.565,0h9.913A.523.523,0,0,1,24,.522v9.912A.523.523,0,0,1,23.478,10.956Zm-13.044,0H.522A.523.523,0,0,1,0,10.434V.522A.523.523,0,0,1,.522,0h9.912a.523.523,0,0,1,.522.522v9.912A.523.523,0,0,1,10.434,10.956Z" transform="translate(6 4)" fill="url(#linear-gradient-Vector-normal-state)"/>
          </g>
        </g>
      </svg>
      <svg className="hover-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
        <defs>
          <filter id="VectorHoverState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="icon_filled_application_appstore_clip_group_" transform="translate(6 4)">
          <rect id="icon_filled_application_appstore_style_" width="24" height="24" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, -6, -4)" filter="url(#VectorHoverState)">
            <path id="Vector-2"  d="M23.478,24H13.565a.522.522,0,0,1-.521-.522V13.565a.521.521,0,0,1,.521-.521h9.913a.522.522,0,0,1,.522.521v9.913A.523.523,0,0,1,23.478,24ZM10.434,24H.522A.523.523,0,0,1,0,23.478V13.565a.522.522,0,0,1,.522-.521h9.912a.522.522,0,0,1,.522.521v9.913A.523.523,0,0,1,10.434,24ZM23.478,10.956H13.565a.522.522,0,0,1-.521-.522V.522A.522.522,0,0,1,13.565,0h9.913A.523.523,0,0,1,24,.522v9.912A.523.523,0,0,1,23.478,10.956Zm-13.044,0H.522A.523.523,0,0,1,0,10.434V.522A.523.523,0,0,1,.522,0h9.912a.523.523,0,0,1,.522.522v9.912A.523.523,0,0,1,10.434,10.956Z" transform="translate(6 4)" fill="#ffd35a"/>
          </g>
        </g>
      </svg>
      <svg className="pressed-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
        <defs>
          <filter id="VectorPressedState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
            <feOffset dy="2" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="2" result="blur"/>
            <feFlood/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g id="icon_filled_application_appstore_clip_group_" transform="translate(6 4)">
          <rect id="icon_filled_application_appstore_style_" width="24" height="24" fill="none"/>
          <g transform="matrix(1, 0, 0, 1, -6, -4)" filter="url(#VectorPressedState)">
            <path id="Vector-2" d="M23.478,24H13.565a.522.522,0,0,1-.521-.522V13.565a.521.521,0,0,1,.521-.521h9.913a.522.522,0,0,1,.522.521v9.913A.523.523,0,0,1,23.478,24ZM10.434,24H.522A.523.523,0,0,1,0,23.478V13.565a.522.522,0,0,1,.522-.521h9.912a.522.522,0,0,1,.522.521v9.913A.523.523,0,0,1,10.434,24ZM23.478,10.956H13.565a.522.522,0,0,1-.521-.522V.522A.522.522,0,0,1,13.565,0h9.913A.523.523,0,0,1,24,.522v9.912A.523.523,0,0,1,23.478,10.956Zm-13.044,0H.522A.523.523,0,0,1,0,10.434V.522A.523.523,0,0,1,.522,0h9.912a.523.523,0,0,1,.522.522v9.912A.523.523,0,0,1,10.434,10.956Z" transform="translate(6 4)" fill="#fc0"/>
          </g>
        </g>
      </svg>
    </>
  )
}

export default Vector