import React from "react";
import LogoIsNew from "../navBar/LogoIsNew";
import {connect} from "react-redux";
import {setCategory} from "@actions/categoryActions";
import Imgs from "@components/Imgs";

function Category({item, selectedCategory, setCategory, ...restProps}) {

    const onSelectCategory = () => {
        setCategory(item);
    }

    return (
        <div {...restProps} className={item.id === selectedCategory.id? "active": ""} onClick={onSelectCategory}>
            <div className="item-content">
                {item.isNew && <LogoIsNew/>}
                <Imgs icon={item.icon} iconHover={item.iconHover} iconPressed={item.iconPressed} />
                <div className="item-content-title">
                     {item.name}
                </div>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        selectedCategory: state.selectedCategory
    }
}

const mapDispatchToProps = {
    setCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);