import React, { useState } from "react"
import Categories from "@components/Categories";

import Vector from "../../resource/Vector";
import Exclusion from "../../resource/Exclusion";
import Line from "./Line";
import {setSettings} from "@actions/settingsActions";
import {connect} from "react-redux";
import classnames from "classnames";



function NavBar({isBigGrid, setSettings, tablesInfo}){

    const setSmallGrid = () => {
        setSettings({
            isBigGrid: false
        })
    }
    const setBigGrid = () => {
        setSettings({
            isBigGrid: true
        })
    }

    return(
        <nav>
            <div className="navLeft">
                <span>{`${tablesInfo.countOfTables}  Tables`}</span>
                <span>{`${tablesInfo.countOfPlayers} Players`}</span>
            </div>
            <Categories className="menu"/>
            <div className="nav-right">
                <div className={classnames("item", {selected: !isBigGrid})} onClick={setSmallGrid}>
                    <Vector />
                </div>
                <Line/>
                <div className={classnames("item", {selected: isBigGrid})} onClick={setBigGrid}>
                    <Exclusion />
                </div>
            </div>
        </nav>
    )
}


function mapStateToProps(state) {
    return {
        isBigGrid: state.settings.isBigGrid,
        tablesInfo: state.tablesInfo
    }
}

const mapDispatchToProps = {
    setSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);