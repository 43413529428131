import React from "react";

function Spanish(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="_x30_7_x2C__Spain_x2C__country_x2C__national_x2C__flag_x2C__world_flag" transform="translate(1627 236)">
      <g id="XMLID_684_" transform="translate(16 76)">
        <path id="XMLID_623_" d="M16,86.667V76H48V86.667l-16,.889Z" transform="translate(-16 -76)" fill="#d6002a"/>
        <path id="XMLID_622_" d="M16,317.556V306.889L32,306l16,.889v10.667Z" transform="translate(-16 -285.556)" fill="#d6002a"/>
        <path id="XMLID_621_" d="M16,196H48v10.667H16Z" transform="translate(-16 -185.333)" fill="#fddb47"/>
      </g>
    </g>
  </g>
</svg>
    )
} 

export default Spanish