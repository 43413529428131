import React from "react";
function English(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="_x30_1_United_Kingdom_x2C__country_x2C__national_x2C__flag_x2C__world_flag" transform="translate(1643 312)">
      <g id="XMLID_684_" transform="translate(0 0)">
        <path id="XMLID_670_" d="M16,103.556V80.444L18,76H28l4,.889L36,76H46l2,4.444v23.111L46,108H36l-4-.889L28,108H18Z" transform="translate(-16 -76)" fill="#0053b5"/>
        <path id="XMLID_669_" d="M16,196H48v10.667H16Z" transform="translate(-16 -185.333)" fill="#fff"/>
        <path id="XMLID_668_" d="M16,216H48v7.111H16Z" transform="translate(-16 -203.556)" fill="#d80027"/>
        <path id="XMLID_667_" d="M196,76h8v32h-8Z" transform="translate(-184 -76)" fill="#fff"/>
        <path id="XMLID_666_" d="M216,76h5.333v32H216Z" transform="translate(-202.667 -76)" fill="#d80027"/>
        <path id="XMLID_665_" d="M328,76h-2l-10,8.889v2.667l5.333-.889L328,80.444Z" transform="translate(-296 -76)" fill="#fff"/>
        <path id="XMLID_664_" d="M328,76,316,86.667h3.333l8.667-8Z" transform="translate(-296 -76)" fill="#d80027"/>
        <path id="XMLID_663_" d="M16,76h2l10,8.889v2.667l-5.333-.889L16,80.444Z" transform="translate(-16 -76)" fill="#fff"/>
        <path id="XMLID_662_" d="M16,76,28,86.667H24.667l-8.667-8Z" transform="translate(-16 -76)" fill="#d80027"/>
        <path id="XMLID_661_" d="M16,317.556h2l10-8.889V306l-5.333.889L16,313.111Z" transform="translate(-16 -285.556)" fill="#fff"/>
        <path id="XMLID_660_" d="M16,326.667,28,316H24.667L16,324Z" transform="translate(-16 -294.667)" fill="#d80027"/>
        <path id="XMLID_659_" d="M328,317.556h-2l-10-8.889V306l5.333.889L328,313.111Z" transform="translate(-296 -285.556)" fill="#fff"/>
        <path id="XMLID_658_" d="M328,326.667,316,316h3.333L328,324Z" transform="translate(-296 -294.667)" fill="#d80027"/>
      </g>
    </g>
  </g>
</svg>
    )
}


export default English