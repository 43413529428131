import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_GAME_START,
    SET_ALERT_MSG,
    SET_GAME_START,
    CONNECT_TO_SOCKET
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";
import {getTokenByUrl, PostResource} from "../../utils/HttpUtil";

function* fetchStartGameViaTembo() {
    const token = getTokenByUrl();
    if (token) {
        const res = yield call(PostResource, `/token/verify`,{token});
        yield put({type: CONNECT_TO_SOCKET, token});
    } else {
        yield put({type: SET_ALERT_MSG, data: {type: 'error', messages: "token_not_found"}});
    }
}

export default function* gameAuthSaga() {
    yield takeLatest(REQUEST_GAME_START, safe(onError, fetchStartGameViaTembo));
}