import React from "react"
import { FormattedMessage } from "react-intl";
import {connect} from "react-redux";
import Popup from "./Popup";
import {closePlayerHistoryPopup, openHistoryPeriodPopup} from "@actions/popupActions";
import {abbrNum} from "@services/numberFormatServices";
import OpenPopup from "./Popup/OpenPopup";
import { TimePeriodsTypeValues } from "@constants/Enums";


function PlayerHistory({playerHistory, closePlayerHistoryPopup, historyPeriod, openHistoryPeriodPopup}) {
    return (
        <Popup title="player_history" className="player-history" onClose={closePlayerHistoryPopup}>
            <div className="second-history" >
                <span onClick={openHistoryPeriodPopup}>
                    <FormattedMessage id={TimePeriodsTypeValues[historyPeriod]}/>
                </span>
                <div><OpenPopup/></div>
            </div>
            <div className="third-history">
                <div>
                    <span><FormattedMessage id="rounds_played"/> </span>
                    <span>{abbrNum(playerHistory.roundsPlayer)}</span>
                </div>
                <div>
                    <span><FormattedMessage id="win"/></span>
                    <span>{abbrNum(playerHistory.win)}</span>

                </div>
                <div>
                    <span><FormattedMessage id="loss"/></span>
                    <span>{abbrNum(playerHistory.loss)}</span>
                </div>
            </div>
        </Popup>
            
    )
}

function mapStateToProps(state) {
    return {
        playerHistory: state.playerHistory,
        historyPeriod: state.historyPeriod
    }
}

const mapDispatchToProps = {
    closePlayerHistoryPopup,
    openHistoryPeriodPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayerHistory);