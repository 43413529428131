import { IntlFormatError } from "@formatjs/intl";
import React from "react";
import { FormattedMessage } from "react-intl";
import PeopleIcon from "./PeopleIcon";
import {abbrNum} from "@services/numberFormatServices";


function GamesInfo({ item }) {
    
    return (
        <div className="gameInfoParent">
            <div className="leftPartGame">
                <p>{item.name}</p>
                <p className="limits"><FormattedMessage id="limits" />: {abbrNum(item.minStake)}-{abbrNum(item.maxStake)}</p>
            </div>
            <div className="rightParthGame">
                <PeopleIcon />
                <p>{item.activePlayers}</p>
            </div>


        </div>
    )
}

export default GamesInfo