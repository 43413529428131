import {
    OPEN_GAME,
    CLOSE_GAME
} from "@actions/actionTypes";


export function openGame(game) {
    return dispatch => {
        dispatch({type: OPEN_GAME, game});
    }
}

export function closeGame() {
    return dispatch => {
        dispatch({type: CLOSE_GAME});
    }
}