import React from "react";
function CheckedIcon(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 28 24.624">
          <defs>
            <filter id="Vector" x="0" y="0" width="28" height="24.624" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="icon_outlined_suggested_check_clip_group_" data-name="icon/outlined/suggested/check (clip group)" transform="translate(6.001 2.3)">
            <rect id="icon_outlined_suggested_check_style_" data-name="icon/outlined/suggested/check (style)" width="16" height="16" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, -6, -2.3)" filter="url(#Vector)">
              <path id="Vector-2" data-name="Vector" d="M15.843,0H14.472a.624.624,0,0,0-.492.239L5.9,10.48,2.02,5.568a.627.627,0,0,0-.492-.239H.157a.156.156,0,0,0-.124.253l5.37,6.8a.629.629,0,0,0,.986,0L15.966.251A.155.155,0,0,0,15.843,0Z" transform="translate(6 4)" fill="#fff"/>
            </g>
          </g>
      </svg>
    )
}


export default CheckedIcon