import React from "react"
export default function Italian(){
    return(
<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="Layer_25" data-name="Layer 25" transform="translate(1639 298.13)">
      <path id="Path_14343" data-name="Path 14343" d="M4,13.87H14.669v32H4Z" transform="translate(0 0)" fill="#009a49"/>
      <path id="Path_14344" data-name="Path 14344" d="M22.67,13.87H33.339v32H22.67Z" transform="translate(-8.001 0)" fill="#fff"/>
      <path id="Path_14345" data-name="Path 14345" d="M41.33,13.87H52v32H41.33Z" transform="translate(-15.999 0)" fill="#ce2b37"/>
    </g>
  </g>
</svg>
    )
}

