import initialStore from "../initialStore";
import {
    SET_SETTINGS
} from "@actions/actionTypes";

export default function settingsReducer(state = initialStore.settings, action) {
    switch (action.type) {
        case SET_SETTINGS:
            return {
                ...state,
                ...action.settings
            }
        default:
            return state;
    }
}