import React, { useEffect, useRef, useState } from "react"

function ImgLoader({src, alt}) {
    const [isLoaded, setIsLoaded] = useState(false);

    const imageLoaded = () => {
        setIsLoaded(true);
    }
    useEffect(() => {
        setIsLoaded(false);
    }, [src])

   return(
        <div className="img-loader">
            <img onLoad={imageLoaded} src={src} alt={alt} style={{
                    display: isLoaded ? "inline-block" : "none"
                }}/>
            <div className="img-loader-loading" style={{
                    display: isLoaded ? "none" : "block"
                }} ></div>
        </div>
    )
}
export default ImgLoader