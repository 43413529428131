import React from "react"
export default function Turkish(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="turkey" transform="translate(1638 226.663)">
      <rect id="Rectangle_1866" data-name="Rectangle 1866" width="48.001" height="32" transform="translate(0 85.337)" fill="#d80027"/>
      <g id="Group_2437" data-name="Group 2437" transform="translate(9.498 94.344)">
        <path id="Path_14331" data-name="Path 14331" d="M221.373,216.787l1.649,2.273,2.671-.866-1.652,2.271,1.649,2.273-2.67-.869-1.652,2.271,0-2.808-2.67-.87,2.671-.866Z" transform="translate(-207.694 -213.471)" fill="#f0f0f0"/>
        <path id="Path_14332" data-name="Path 14332" d="M110.486,194.087a5.682,5.682,0,1,1,2.7-10.68,6.993,6.993,0,1,0,0,10A5.654,5.654,0,0,1,110.486,194.087Z" transform="translate(-101.308 -181.413)" fill="#f0f0f0"/>
      </g>
    </g>
  </g>
</svg>
    )
}




