import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import { FormattedMessage } from "react-intl";
import { closeGame } from "@actions/iframeActions";
import CloseIcon from "@resource/CloseIcon";
import {getTokenByUrl} from "../../utils/HttpUtil";
import { insertUrlParam, removeUrlParameter } from "../../utils/commonUtil";

function GameIframe({selectedGame, closeGame}) {
    const [width, setWidth] = useState("100%");
    const [height, setheight] = useState("100%");
    const [isMobile, setIsMobile] = useState(false);
    const host = window.location.protocol + "//" + window.location.host;
     
    const setDevicePixelRatio = () => {
        let root = document.documentElement;
        root.style.setProperty('--device-pixel-ratio', 1);
    }

    const setDeviceClassName = () => {
        let scaleX = 0;
        let scaleY = 0;
        let width = 0;
        let height = 0;
        setDevicePixelRatio();
        const el = document.querySelector("iframe");
        if (el == null) return;
        const loading = document.querySelector(".loading .border-box");
        document.body.classList.remove("tab")
        document.body.classList.remove("smallPc")
        document.body.classList.remove("pc")
        if (window.innerWidth <= 540) {
            el.style.transform = "unset";
            loading && (loading.style.transform = "unset");
            setIsMobile(true)
            setWidth("100%")
            setheight("100%")
            return
        } else if (window.innerWidth < 775 ) {
            scaleX = window.innerWidth / 768
            scaleY = window.innerHeight / 1024
            width = 768 ;
            height = 1024 ;
            document.body.classList.add("tab")
            setIsMobile(false)
        } else if(window.innerWidth < 1281 ) {
            scaleX = window.innerWidth / 1280
            scaleY = window.innerHeight  / 800
            width = 1280 ;
            height = 800 ;
            document.body.classList.add("smallPc")
            setIsMobile(false)
        } else {
            scaleX = window.innerWidth / 1920
            scaleY = window.innerHeight  / 1080
            width = 1920 ;
            height = 1080 ;
            document.body.classList.add("pc")
            setIsMobile(false)
        }
        let scale = 1;
        let translateX = 0;
        let translateY = 0;
        let isCalcByWidth = false;
        setWidth(width)
        setheight(height)
        if (scaleX < scaleY ) {
            scale = scaleX;
            isCalcByWidth = true;
        } else {
            scale = scaleY;
            isCalcByWidth = false;
        }
        translateX = !isCalcByWidth? ( window.innerWidth - scale * width) / 2 / scale : 0;
        translateY =  isCalcByWidth?( window.innerHeight - scale * height) / 2 / scale : 0;
        el.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
        // loading && (loading.style.transform = `scale(${scale}) translate(${translateX}px, ${translateY}px)`);
        let root = document.documentElement;
        root.style.setProperty('--scale-value', scale);
    }

    const close = () => {
        closeGame();
    }
    useEffect(() => {
        function checkedDevice() {
            setDeviceClassName();
        }

        function handleMessage(event) {
            switch(event.data) {   
                 case "CLOSE":
                    close();
                    break;
                default:
                    // console.log("unexpected message", event)
            } 
        }
        window.addEventListener('message', handleMessage);
        window.addEventListener("resize", checkedDevice)
        return () => {
            window.removeEventListener('message', handleMessage);
            window.removeEventListener("resize", checkedDevice)
        }
    }, [])

    useEffect(() => {
        if (selectedGame.url) {
            insertUrlParam("gameId", selectedGame.id)
            sessionStorage.setItem('gameId', selectedGame.id);
            setDeviceClassName();
        }
        return () => {
            removeUrlParameter("gameId");
            sessionStorage.removeItem("gameId");
        }
    }, [selectedGame])

    return  selectedGame.url && <div className="game-iframe">
            {/* <div className="close-icon" onClick={closeGame}>
                <CloseIcon />
            </div> */}
            <iframe title={selectedGame.name}
                    width={width}
                    height={height}
                    allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                    src={`${selectedGame.url}?token=${getTokenByUrl()}&home=${host}&isOpenedByLobby=true`} />
    </div>
}

function mapStateToProps(state) {
    return {
        selectedGame: state.selectedGame
    }
  }
  
  const mapDispatchToProps = {
      closeGame
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(GameIframe);