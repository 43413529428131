import io from "socket.io-client";
import { getTokenByUrl } from './HttpUtil';

const host = process.env.SYSTEM_SOCKET_URI;

function SocketClient() {
    this.client = null;
    this.oldSubscribers = [];

    this.connect = function (token) {
        try {
            this.client = io.connect(host, {transports: ['websocket'], path: '/ws/', secure: true, query: {token}});
            if (this.oldSubscribers.length) {
                this.oldSubscribers.forEach(sub => {
                    this.client.on(sub.eventName, sub.callBack);
                });
            }
        } catch (e) {
            console.error(e)
        }
        return this;
    }

    this.on = function (eventName, callBack) {
        if (this.client) {
            this.client.on(eventName, callBack)
        } else {
            this.oldSubscribers.push({eventName, callBack})
        }
    }

    this.off = function (eventName, callBack) {
        if (this.client) {
            this.client.off(eventName, callBack)
        } else {
            this.oldSubscribers = this.oldSubscribers.filter(item => item.eventName != eventName && item.callBack != callBack);
        }
    }

    this.emit = function (eventName, data) {
        try {
            let message = {Type: +eventName};
            if (data) {
                message.Data = data;
            }

            this.client.emit(JSON.stringify(message));
        } catch (e) {
            console.error(e)
        }
    }
}

export default function () {
    return new SocketClient()
}
