import {
    CATEGORY_UPDATE,
    GAME_UPDATE,
    GAME_REMOVE,
    GAME_ADD,
    BALANCE_UPDATE,
    FIRST_INFO,
    ADD_GAME_TO_SELECTED_CATEGORY,
    REMOVE_GAME_TO_SELECTED_CATEGORY,
    UPDATE_GAME_TO_SELECTED_CATEGORY,
    REMOVE_GAME,
    ADD_GAME,
    UPDATE_GAME,
    SET_BALANCE,
    REQUEST_CATEGORIES
} from "@actions/actionTypes";
import { SET_USER } from "./actionTypes";
import { toLowercaseKey } from "../../utils/commonUtil";

export function subscribeForCategoryUpdates() {
    return (dispatch, getState) => dispatch({
        eventOn: CATEGORY_UPDATE,
        handle: (_) => {
            // console.log('CATEGORY_UPDATE', _);
            const {settings} = getState();
            dispatch({type: REQUEST_CATEGORIES, lang: settings.lang});
        }
    });
}


export function subscribeForGameUpdates() {
    return (dispatch, getState) => dispatch({
        eventOn: GAME_UPDATE,
        handle: async (obj) => {
            // console.log('GAME_UPDATE', obj);
            const game = toLowercaseKey(obj)
            dispatch({type: UPDATE_GAME, game});
            const { selectedCategory } = getState();
            if (selectedCategory.id === game.category) {
                dispatch({type: UPDATE_GAME_TO_SELECTED_CATEGORY, game})
            }
        }
    });
}


export function subscribeForGameAdd() {
    return (dispatch, getState) => dispatch({
        eventOn: GAME_ADD,
        handle: async (obj) => {
            // console.log("GAME_ADD", obj)
            const game = toLowercaseKey(obj);
            dispatch({type: ADD_GAME, game});
            const { selectedCategory } = getState();
            if (selectedCategory.id === game.category) {
                dispatch({type: ADD_GAME_TO_SELECTED_CATEGORY, game});
            }
        }
    });
}

export function subscribeForGameRemove() {
    return (dispatch, getState) => dispatch({
        eventOn: GAME_REMOVE,
        handle: async (obj) => {
            const game = toLowercaseKey(obj);
            dispatch({type: REMOVE_GAME, game});
            // console.log('GAME_REMOVE', game)
            const { selectedCategory } = getState();
            if (selectedCategory.id === game.category) {
                dispatch({type: REMOVE_GAME_TO_SELECTED_CATEGORY, game});
            }
        }
    });
}

export function subscribeForBalanceUpdate() {
    return (dispatch) => dispatch({
        eventOn: BALANCE_UPDATE,
        handle: async (balance) => {
            // console.log('BALANCE_UPDATE', balance)
            dispatch({type: SET_BALANCE, balance});
        }
    });
}

export function subscribeForFirstInfo() {
    return (dispatch) => dispatch({
        eventOn: FIRST_INFO,
        handle: async (obj) => {
            const user = toLowercaseKey(obj);
            // console.log(user)
            dispatch({type: SET_USER, user});
            // console.log('FIRST_INFO', obj)
        }
    });
}
