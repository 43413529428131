
const API_URL = process.env.SYSTEM_API_PATH;

export async function GetResource(route, params = {}, api, isJson = true) {
    const url = new URL(`${api || API_URL}${route}`);
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });
    if (res.status !== 200 && res.status !== 404) {
        throw new Error(res);
    }
    if (res.status === 404) return {};
    if (!isJson) return res.text();
    const body = res.body ? await res.json() : null;
    return body.data ? body.data : body;
}

export async function PostResource(route, data, url) {
    if (!url) url = `${API_URL}${route}`;
    else url = `${url}${route}`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "content-type": "application/json; charset=utf-8"
            // Authorization: `Bearer ${GetToken()}`,
        },
        body: JSON.stringify(data),
    });
    let body = {};
    try {
        body = await res.json();
    } catch (e) {

    }
    if (!res.ok) throw new Error(body.message || "Unexpected error");
    return body;
}

export async function PutResource(route, data, url) {
    if(!url) url = `${API_URL}${route}`;
    else url = `${url}${route}`;
    const res = await fetch(url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${GetToken()}`,
        },
        body: JSON.stringify(data),
    });
    const body = await res.json();
    if (!res.ok) throw new Error(body.error || body.message);
    return body;
}

export async function DeleteResource(route, data, api) {
    const url = new URL(`${api || API_URL}${route}`);
    const fetchBody = {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            // Authorization: `Bearer ${GetToken()}`,
            "content-type": "application/json; charset=utf-8"
        }
    };
    if (data){
        fetchBody.body = JSON.stringify(data);
    }
    const res = await fetch(url, {...fetchBody});
    const body = await res.json();
    if (!res.ok) throw new Error(body.error || body.message);
    return body;
}

export async function UploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return fetch(`${API_URL}/file`, {
        method: "POST",
        headers: {
            // Authorization: `Bearer ${GetToken()}`
        },
        body: formData
    });
}


export function getSessionIdByUrl() {
    let params = new URLSearchParams(document.location.search.substring(1));
    return params.get('session');
}

export function getTokenByUrl() {
    let params = new URLSearchParams(document.location.search.substring(1));
    return params.get('token');
}

export function getParamByUrl(param) {
    let params = new URLSearchParams(document.location.search.substring(1));
    return params.get(param);
}