import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider as ReduxProvider} from "react-redux";
import {DefaultLanguage, DefaultLanguageCode} from '@constants/Enums';
import configureStores from "./redux/configureStore";
import App from './App';
import translations from './translations';

const store = configureStores();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ReduxProvider store={store}>
      <App locale={DefaultLanguage} translations={translations}/>
    </ReduxProvider>
);