import initialStore from "../initialStore";
import {
    SET_USER,
    SET_BALANCE
} from "@actions/actionTypes";

export default function userReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_USER:
            return action.user;
        case SET_BALANCE:
            return {
                ...state,
                balance: action.balance
            };
        default:
            return state;
    }
}