import React, { useState } from "react";
import {connect} from "react-redux";
import Games from "./Games";
import classnames from "classnames";
import {openGame} from "@actions/iframeActions";


function Main({isBigGrid, games, openGame}) {

    const open = (game) => {
        openGame(game);
    }

    return (
        <div className={classnames("gamesList", {bigGrid: isBigGrid})}>
                <Games games={games} open={open}/>
        </div>
    )
}
function mapStateToProps(state) {
    return {
        isBigGrid: state.settings.isBigGrid,
        games: state.games
    }
}

const mapDispatchToProps = {
    openGame
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);