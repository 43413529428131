import initialStore from "../initialStore";
import {
    SET_GAMES,
    ADD_GAME_TO_SELECTED_CATEGORY,
    REMOVE_GAME_TO_SELECTED_CATEGORY,
    UPDATE_GAME_TO_SELECTED_CATEGORY
} from "@actions/actionTypes";

export default function gamesReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_GAMES:
            return action.games;
        case ADD_GAME_TO_SELECTED_CATEGORY:
            return [
                ...state,
                action.game
            ];
        case REMOVE_GAME_TO_SELECTED_CATEGORY:
            return state.filter(game => game.id !== action.game.id);
        case UPDATE_GAME_TO_SELECTED_CATEGORY:
            return state.map(game => {
                if (game.id === action.game.id) {
                    return {
                        ...game,
                        ...action.game
                    }
                }
                return game;
            });
        default:
            return state;
    }
}