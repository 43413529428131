import {combineReducers} from "redux";
import {
    availableLanguagesReducer as availableLanguages,
    selectedLanguagesReducer as selectedLanguages
} from "./languagesReducer";
import settings from "./settingsReducer";
import allGames from "./allGamesReducer";
import games from "./gamesReducer";
import selectedCategory from "./selectedCategoryReducer";
import categories from "./categoriesReducer";
import tablesInfo from "./tablesInfoReducer";
import playerHistory from "./playerHistoryReducer";
import popups from "./popupsReducer";
import user from "./userReducer";
import globalInfo from "./globalInfoRducer";
import selectedGame from "./selectedGameReducer";
import historyPeriod from "./historyPeriodReducer";
import alertMessage from "./alertMessagesReducer";

const rootReducer = combineReducers({
    availableLanguages,
    selectedLanguages,
    settings,
    allGames,
    selectedCategory,
    categories,
    tablesInfo,
    games,
    playerHistory,
    popups,
    user,
    globalInfo,
    selectedGame,
    historyPeriod,
    alertMessage
});

export default rootReducer;
