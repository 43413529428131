import {call, put, takeLatest} from "redux-saga/effects";
import {GetResource} from "../../utils/HttpUtil";
import {
    SET_USER,
    REQUEST_USER
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";

function* fetchUser() {
    const user = yield call(GetResource, `/user`);
    yield put({type: SET_USER, user});
}

export default function* userSaga() {
    yield takeLatest(REQUEST_USER, safe(onError, fetchUser));
}