import React from "react";

function BalanceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32.258 30">
      <defs>
        <filter id="Union_62BalanceIcon" x="0" y="0" width="32.258" height="30" filterUnits="userSpaceOnUse">
          <feOffset dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feFlood />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="coin-stack_1_" data-name="coin-stack (1)" transform="translate(6 -18.379)">
        <rect id="Rectangle_1093" data-name="Rectangle 1093" width="22" height="18" transform="translate(-1 22.379)" fill="none" />
        <g transform="matrix(1, 0, 0, 1, -6, 18.38)" filter="url(#Union_62BalanceIcon)">
          <path id="Union_62-2BalanceIcon" data-name="Union 62" d="M8.487,15.33v-.578A1.3,1.3,0,0,1,8.61,14.2c.556,1.213,2.924,2.123,5.762,2.123s5.206-.91,5.763-2.123a1.315,1.315,0,0,1,.124.547v.578c0,1.474-2.636,2.67-5.886,2.67S8.487,16.8,8.487,15.33ZM0,13.73v-.578A1.3,1.3,0,0,1,.124,12.6c.556,1.212,2.924,2.122,5.763,2.122a12.616,12.616,0,0,0,1.639-.106v.709a2.1,2.1,0,0,0,.221.933,12.5,12.5,0,0,1-1.86.136C2.635,16.4,0,15.2,0,13.73Zm8.486-1.379v-.579a1.3,1.3,0,0,1,.124-.547c.556,1.212,2.924,2.123,5.762,2.123s5.206-.91,5.763-2.123a1.318,1.318,0,0,1,.124.547v.579c0,1.475-2.636,2.669-5.886,2.669S8.487,13.825,8.487,12.351ZM0,10.75v-.58a1.316,1.316,0,0,1,.124-.546c.556,1.212,2.925,2.122,5.763,2.122a12.4,12.4,0,0,0,1.639-.106v1.673a12.619,12.619,0,0,1-1.639.1C2.635,13.419,0,12.225,0,10.75ZM8.487,9.208V8.63a1.3,1.3,0,0,1,.124-.548c.556,1.213,2.924,2.123,5.762,2.123s5.206-.91,5.763-2.123a1.319,1.319,0,0,1,.124.548v.578c0,1.475-2.636,2.67-5.886,2.67S8.487,10.683,8.487,9.208ZM0,7.769V7.191c0-1.475,2.635-2.67,5.886-2.67a12.664,12.664,0,0,1,1.639.1v5.708a12.62,12.62,0,0,1-1.639.1C2.635,10.439,0,9.244,0,7.769Zm8.486-1.54V5.65A1.3,1.3,0,0,1,8.61,5.1c.556,1.212,2.924,2.122,5.762,2.122s5.206-.911,5.763-2.122a1.316,1.316,0,0,1,.124.547v.579c0,1.473-2.636,2.669-5.886,2.669S8.487,7.7,8.487,6.229Zm0-2.981V2.67C8.487,1.2,11.123,0,14.373,0s5.885,1.2,5.885,2.67v.578c0,1.474-2.636,2.67-5.885,2.67S8.487,4.723,8.487,3.248Z" transform="translate(6 4)" fill="#fc0" />
        </g>
      </g>
    </svg>
  )
}

export default BalanceIcon