export const DefaultLanguage = 'en';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const TimePeriodsType = {
    'LastDay': 0,
    'LastWeek': 1,
    'LastMonth': 2,
    'Last3Months': 3,
    'LastYear': 4
}

export const TimePeriodsTypeValues = {
    0: 'LastDay',
    1: 'LastWeek',
    2: 'LastMonth',
    3: 'Last3Months',
    4: 'LastYear'
}

export const TimePeriodsTypeNames = Object.keys(TimePeriodsType); //["LastDay", "LastWeek", "LastWeek", "Last3Mounts", "LastYear"]