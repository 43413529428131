import React from "react";
import { FormattedMessage } from "react-intl";
import PeriodList from "./PeriodList";
import PeriodHistoryLabel from "./PeriodHistoryLabel"
import { connect } from "react-redux";
import {
    closeHistoryPeriodPopup
} from "@actions/popupActions";

 function PeriodHistory ({onClose, closeHistoryPeriodPopup}) {
    const close = () => {
        onClose && onClose();
    }

    return (
        <div className="popup-wraper" onClick={close}>
            <div className="popup-wraper" onClick={() => {closeHistoryPeriodPopup()}}>
            
                <div className="period_history">
                    <PeriodList />
                </div>
            </div>
            
        </div>
       
    )
}


function mapStateToProps(state) {
    return {
    }
}
 
const MapStateToPropsParam = {
    closeHistoryPeriodPopup
}

export default connect(mapStateToProps, MapStateToPropsParam) (PeriodHistory)