import React from "react"
import GamesInfo from "./GamesInfo";
import ImgLoader from "../ImgLoader";
import GameStartButton from "../GameStartButton/GameStartButton";

function Games({games, open}){

    return(
        <div className="games">
            {
                games.map((game) => {
                    return (
                        <div className="gamesInfo" key={game.id} onClick={() => open(game)} >
                            <GamesInfo item={game}/>
                            <ImgLoader key={game.id} src={game.dealerImage || game.image} alt={game.name} />
                            <GameStartButton onClick={() => open(game)} />
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Games