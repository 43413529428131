import initialStore from "../initialStore";
import {
    SET_HISTORY_PERIOD
} from "@actions/actionTypes";

export default function historyPeriodReducer(state = initialStore.historyPeriod, action) {
    switch (action.type) {
        case SET_HISTORY_PERIOD:
            return action.value;
        default:
            return state;
    }
}