import {all, call} from 'redux-saga/effects';
import errorSaga from "./errorSaga";
import userSaga from "./userSaga";
import gamesSaga from "./gamesSaga";
import categoriesSaga from './categoriesSaga';
import gameAuthSaga from "./gameAuthSaga";

export default function* rootSaga() {
    yield all([
        call(errorSaga),
        call(userSaga),
        call(gamesSaga),
        call(gameAuthSaga),
        call(categoriesSaga)
    ])
}