import React from "react";

export default function OpenPopup(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 23 19.312">
  <defs>
    <filter id="Vector" x="0" y="0.688" width="23" height="18.625" filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2" result="blur"/>
      <feFlood/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="icon_outlined_directional_caret-right_clip_group_" data-name="icon/outlined/directional/caret-right (clip group)" transform="translate(19.5) rotate(90)">
    <rect id="icon_outlined_directional_caret-right_style_" data-name="icon/outlined/directional/caret-right (style)" width="16" height="16" fill="none"/>
    <g transform="matrix(0, -1, 1, 0, 0, 19.5)" filter="url(#Vector)">
      <path id="Vector-2" data-name="Vector" d="M6.5,5.211.547.08A.336.336,0,0,0,0,.369V10.631a.336.336,0,0,0,.547.289L6.5,5.789A.391.391,0,0,0,6.5,5.211Z" transform="translate(17 4.69) rotate(90)" fill="#fff"/>
    </g>
  </g>
</svg>
    )
}