import React from "react";

export default function PeriodHistoryLabel(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 24 21.468">
  <defs>
    <filter id="Vector" x="0" y="0" width="24" height="21.468" filterUnits="userSpaceOnUse">
      <feOffset dy="2" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="2" result="blur"/>
      <feFlood/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="icon_outlined_suggested_check_clip_group_" data-name="icon/outlined/suggested/check (clip group)" transform="translate(6.001 2.725)">
    <rect id="icon_outlined_suggested_check_style_" data-name="icon/outlined/suggested/check (style)" width="12" height="12" transform="translate(0)" fill="none"/>
    <g transform="matrix(1, 0, 0, 1, -6, -2.73)" filter="url(#Vector)">
      <path id="Vector-2" data-name="Vector" d="M11.882,0H10.854a.468.468,0,0,0-.369.179L4.422,7.86,1.515,4.176A.471.471,0,0,0,1.146,4H.118a.117.117,0,0,0-.093.19l4.028,5.1a.472.472,0,0,0,.74,0l7.182-9.1A.116.116,0,0,0,11.882,0Z" transform="translate(6 4)" fill="#fff"/>
    </g>
  </g>
</svg>
    )
}