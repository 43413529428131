import React, { useRef } from "react";


function HistoryIcon() {

    const linerGradient = useRef("linear-gradient-HistoryIcon-normal-state" + Math.random());

    return (
        <>
            <svg className="normal-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
                <defs>
                    <linearGradient id={linerGradient.current} x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                        <stop offset="0" stopColor="#f2f2f2"/>
                        <stop offset="1" stopColor="#bfbfbf"/>
                    </linearGradient>
                    <filter id="VectorHistoryIconNormalState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
                        <feOffset dy="2" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="2" result="blur"/>
                        <feFlood/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Group_1876" transform="translate(-1572 -20)">
                    <rect id="Rectangle_1099" width="24" height="24" transform="translate(1578 24)" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 1572, 20)" filter="url(#VectorHistoryIconNormalState)">
                        <path id="Vector-2" d="M12.369,24a12.489,12.489,0,0,1-8.7-3.474A11.763,11.763,0,0,1,3.573,3.551,12.479,12.479,0,0,1,12.364,0h.011a12.434,12.434,0,0,1,9.74,4.61L20.393,5.916A10.181,10.181,0,0,0,16.332,2.9a10.5,10.5,0,0,0-7.922,0A10.144,10.144,0,0,0,5.172,5.015,9.776,9.776,0,0,0,2.988,8.154a9.673,9.673,0,0,0-.007,7.689,9.921,9.921,0,0,0,2.185,3.14A10.142,10.142,0,0,0,8.4,21.1a10.353,10.353,0,0,0,3.963.776h.016A10.35,10.35,0,0,0,16.33,21.1a10.124,10.124,0,0,0,3.237-2.118,9.815,9.815,0,0,0,2.185-3.14c.06-.139.118-.28.172-.421a.227.227,0,0,1,.117-.124.232.232,0,0,1,.173-.01l1.632.543a.226.226,0,0,1,.14.29,12.017,12.017,0,0,1-4.456,5.663A12.542,12.542,0,0,1,12.369,24Zm3.7-7.17a.24.24,0,0,1-.139-.044l-4.756-3.368a.22.22,0,0,1-.1-.181V5.553a.227.227,0,0,1,.23-.223H12.7a.227.227,0,0,1,.23.223v6.908l4.1,2.877a.213.213,0,0,1,.092.141.22.22,0,0,1-.043.171l-.823,1.089A.227.227,0,0,1,16.07,16.83ZM23.586,8.5a.237.237,0,0,1-.053-.006L19.021,7.428a.231.231,0,0,1-.112-.061.219.219,0,0,1-.06-.109.223.223,0,0,1,.009-.123.228.228,0,0,1,.075-.1L23.47,3.6a.226.226,0,0,1,.14-.049.228.228,0,0,1,.23.225l-.022,4.507A.227.227,0,0,1,23.586,8.5Z" transform="translate(6 4)" fill={`url(#${linerGradient.current})`}/>
                    </g>
                </g>
            </svg>
            <svg className="hover-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
                <defs>
                    <filter id="VectorHistoryIconHoverState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
                        <feOffset dy="2" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="2" result="blur"/>
                        <feFlood/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Group_1876" transform="translate(-1572 -20)">
                    <rect id="Rectangle_1099" width="24" height="24" transform="translate(1578 24)" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 1572, 20)" filter="url(#VectorHistoryIconHoverState)">
                        <path id="Vector-2" d="M12.369,24a12.489,12.489,0,0,1-8.7-3.474A11.763,11.763,0,0,1,3.573,3.551,12.479,12.479,0,0,1,12.364,0h.011a12.434,12.434,0,0,1,9.74,4.61L20.393,5.916A10.181,10.181,0,0,0,16.332,2.9a10.5,10.5,0,0,0-7.922,0A10.144,10.144,0,0,0,5.172,5.015,9.776,9.776,0,0,0,2.988,8.154a9.673,9.673,0,0,0-.007,7.689,9.921,9.921,0,0,0,2.185,3.14A10.142,10.142,0,0,0,8.4,21.1a10.353,10.353,0,0,0,3.963.776h.016A10.35,10.35,0,0,0,16.33,21.1a10.124,10.124,0,0,0,3.237-2.118,9.815,9.815,0,0,0,2.185-3.14c.06-.139.118-.28.172-.421a.227.227,0,0,1,.117-.124.232.232,0,0,1,.173-.01l1.632.543a.226.226,0,0,1,.14.29,12.017,12.017,0,0,1-4.456,5.663A12.542,12.542,0,0,1,12.369,24Zm3.7-7.17a.24.24,0,0,1-.139-.044l-4.756-3.368a.22.22,0,0,1-.1-.181V5.553a.227.227,0,0,1,.23-.223H12.7a.227.227,0,0,1,.23.223v6.908l4.1,2.877a.213.213,0,0,1,.092.141.22.22,0,0,1-.043.171l-.823,1.089A.227.227,0,0,1,16.07,16.83ZM23.586,8.5a.237.237,0,0,1-.053-.006L19.021,7.428a.231.231,0,0,1-.112-.061.219.219,0,0,1-.06-.109.223.223,0,0,1,.009-.123.228.228,0,0,1,.075-.1L23.47,3.6a.226.226,0,0,1,.14-.049.228.228,0,0,1,.23.225l-.022,4.507A.227.227,0,0,1,23.586,8.5Z" transform="translate(6 4)" fill="#ffd35a"/>
                    </g>
                </g>
            </svg>
            <svg className="pressed-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="36" height="36" viewBox="0 0 36 36">
                <defs>
                    <filter id="VectorHistoryIconPressedState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
                        <feOffset dy="2" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="2" result="blur"/>
                        <feFlood/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                    </filter>
                </defs>
                <g id="Group_1876" transform="translate(-1572 -20)">
                    <rect id="Rectangle_1099" width="24" height="24" transform="translate(1578 24)" fill="none"/>
                    <g transform="matrix(1, 0, 0, 1, 1572, 20)" filter="url(#VectorHistoryIconPressedState)">
                        <path id="Vector-2" d="M12.369,24a12.489,12.489,0,0,1-8.7-3.474A11.763,11.763,0,0,1,3.573,3.551,12.479,12.479,0,0,1,12.364,0h.011a12.434,12.434,0,0,1,9.74,4.61L20.393,5.916A10.181,10.181,0,0,0,16.332,2.9a10.5,10.5,0,0,0-7.922,0A10.144,10.144,0,0,0,5.172,5.015,9.776,9.776,0,0,0,2.988,8.154a9.673,9.673,0,0,0-.007,7.689,9.921,9.921,0,0,0,2.185,3.14A10.142,10.142,0,0,0,8.4,21.1a10.353,10.353,0,0,0,3.963.776h.016A10.35,10.35,0,0,0,16.33,21.1a10.124,10.124,0,0,0,3.237-2.118,9.815,9.815,0,0,0,2.185-3.14c.06-.139.118-.28.172-.421a.227.227,0,0,1,.117-.124.232.232,0,0,1,.173-.01l1.632.543a.226.226,0,0,1,.14.29,12.017,12.017,0,0,1-4.456,5.663A12.542,12.542,0,0,1,12.369,24Zm3.7-7.17a.24.24,0,0,1-.139-.044l-4.756-3.368a.22.22,0,0,1-.1-.181V5.553a.227.227,0,0,1,.23-.223H12.7a.227.227,0,0,1,.23.223v6.908l4.1,2.877a.213.213,0,0,1,.092.141.22.22,0,0,1-.043.171l-.823,1.089A.227.227,0,0,1,16.07,16.83ZM23.586,8.5a.237.237,0,0,1-.053-.006L19.021,7.428a.231.231,0,0,1-.112-.061.219.219,0,0,1-.06-.109.223.223,0,0,1,.009-.123.228.228,0,0,1,.075-.1L23.47,3.6a.226.226,0,0,1,.14-.049.228.228,0,0,1,.23.225l-.022,4.507A.227.227,0,0,1,23.586,8.5Z" transform="translate(6 4)" fill="#fc0"/>
                    </g>
                </g>
            </svg>
        </>
    )
}
export default HistoryIcon