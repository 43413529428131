import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import HomeIcon from '../resource/HomeIcon';
import FullScreenIcon from '../resource/FullScreenIcon';
import ExitFullScreenIcon from '../resource/ExitFullScreenIcon';
import HistoryIcon from '../resource/HistoryIcon';
import LanguagesIcon from '../resource/LanguagesIcon';
// import SupportIcon from '../resource/SupportIcon';
import HeaderActions from '../components/headerAction/HeaderActions';
import BalanceIcon from '../resource/BalanceIcon';
import MenuIcon from '../resource/MenuIcon';
import { FormattedMessage } from 'react-intl';
import PlayerHistory from '../components/PlayerHistory';
import LanguagesPopup from '../components/LanguagesPopup';
import MenuPopup from "../components/MenuPopup";
import PeriodHistory from "../components/PeriodHistory"
import {
    openPlayerHistoryPopup,
    openLanguagesPopup,
    openMenuPopup
} from "@actions/popupActions";
import {abbrNum} from "@services/numberFormatServices";



function Header({isShowPlayerHostory, isShowMenu, isShowLanguages, isShowHistory, user, openPlayerHistoryPopup, openLanguagesPopup, openMenuPopup}) {

    const [userBalanc, setUserBalanc] = useState(user.balance || "")
    const [fullScreen, setFullScreen] = useState(!!document.fullscreenElement);

    useEffect(() => {
        const fullscreenchanged = (event) => {
            if (document.fullscreenElement) {
                setFullScreen(true);
            } else {
                setFullScreen(false);
            }
          }
          document.addEventListener('fullscreenchange', fullscreenchanged);

          return () => {
            document.removeEventListener('fullscreenchange', fullscreenchanged);
          }
    }, [])

    const fullScreenToggle = () => {
        const root = document.body;
        if (!!document.fullscreenElement) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
              }
        } else {
            if (root.requestFullscreen) {
                root.requestFullscreen({navigationUI: "hide"}).then(() => {
    
                }).catch(e => {
                    console.error(e)
                });
            } else if (root.webkitRequestFullscreen) { /* Safari */
                root.webkitRequestFullscreen({navigationUI: "hide"});
            } else if (root.msRequestFullscreen) { /* IE11 */
                root.msRequestFullscreen({navigationUI: "hide"});
            }
        }
    }

    useEffect(() => {
        if (user.balance !== undefined) {
            setUserBalanc(abbrNum(user.balance));
        } else {
            setUserBalanc(0);
        }
        
    }, [user.balance])

    const leftActions = [
        {
            name: <FormattedMessage id = "home"/>,
            icon: <HomeIcon />,
            action: () => console.log("home")
        },
        {
            name: !!document.fullscreenElement ?  <FormattedMessage id = "exit_full_screen"/> : <FormattedMessage id = "full_screen"/>,
            icon: !!document.fullscreenElement ?<ExitFullScreenIcon /> : <FullScreenIcon />,
            className: "hide-when-mobile",
            action: () => {
                fullScreenToggle();
            }
        },
    
    ]
    
    const rightActions = [
        {
            name: <FormattedMessage id = "history"/>,
            icon: <HistoryIcon />,
            className: "hide-when-mobile",
            action: () => {
                openPlayerHistoryPopup();
            }
        },
        {
            name: <FormattedMessage id = "languages"/>,
            icon: <LanguagesIcon />,
            className: "hide-when-mobile",
            action: () => {
                openLanguagesPopup()
            }
        },
        // {
        //     name: <FormattedMessage id = "support"/>,
        //     icon: <SupportIcon />,
        //      className: "hide-when-mobile",
        //     action: () => console.log("support")
        // },
        {
            name: <FormattedMessage id = "menu"/>,
            icon: <MenuIcon />,
            className: "show-when-mobile menu-button",
            action: () => {
                openMenuPopup()
            }
        }
    ]

    return (
        <header className='header'>
            <div className='leftPart'>
                <HeaderActions actionList={leftActions} />
            </div>
            <div className='user-info'>
                <span><FormattedMessage id = "welcome"/></span>
                <div>
                    <BalanceIcon />
                    <span className='user-info-lable'><FormattedMessage id = "balance"/>:</span>
                    <span className='balance'>{userBalanc}</span>
                </div>

            </div>
            <div className='rightPart'>
                <HeaderActions actionList={rightActions} />
            </div>
            
            {isShowPlayerHostory && <PlayerHistory/>}
            {isShowHistory && <PeriodHistory/>}
            
            {isShowLanguages && <LanguagesPopup/>}
            {isShowMenu && <MenuPopup />} 
        </header>
    )
}


function mapStateToProps(state) {
    return {
        isShowPlayerHostory: state.popups.isShowPlayerHostory,
        isShowMenu: state.popups.isShowMenu,
        isShowLanguages: state.popups.isShowLanguages,
        isShowHistory: state.popups.isShowHistory,
        user: state.user
    }
}

const mapDispatchToProps = {
    openPlayerHistoryPopup,
    openLanguagesPopup,
    openMenuPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);