export function toLowercaseKey(object) {
    const keys = Object.keys(object);
    const obj = {};
    keys.forEach(key => {
        const arr = key.split('');
        arr[0] = arr[0].toLowerCase();
        obj[arr.join('')] = object[key];
    })
    return obj;
}

export function insertUrlParam(key, value) {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }
}

export function removeUrlParameter(paramKey) {
    const url = window.location.href;
    var r = new URL(url);
    r.searchParams.delete(paramKey);
    const newUrl = r.href;
    window.history.pushState({ path: newUrl }, '', newUrl);
}