
import {
    CONNECT_TO_SOCKET,
    REQUEST_GAME_START
} from "@actions/actionTypes"

export function gameStart() {
    return dispatch => {
        dispatch({
            type: REQUEST_GAME_START,
        })
    }
}

export function connectToSocket(token) {
    return dispatch => {
        dispatch({
            type: CONNECT_TO_SOCKET,
            token
        })
    }
}