import React from "react"
import { FormattedMessage } from "react-intl";
import {connect} from "react-redux";
import Popup from "../Popup";
import {
    openPlayerHistoryPopup,
    openLanguagesPopup,
    openMenuPopup,
    closeMenuPopup
} from "@actions/popupActions";
import LanguagesIcon from '@resource/LanguagesIcon';
import HistoryIcon from '@resource/HistoryIcon';
import ListItem from "@components/ListItem";


function MenuPopup({closeMenuPopup, openPlayerHistoryPopup, openLanguagesPopup}) {

    const openLanguagePopup = () => {
        openLanguagesPopup();
    }
    const openhistoryPopup = () => {
        openPlayerHistoryPopup();
    }
    return (
        <Popup title="menu" className="menu-popup" onClose={closeMenuPopup}>
            <ul className="list">
                <ListItem icon={<LanguagesIcon />} title="languages" onClick={openLanguagePopup}/>
                <ListItem icon={<HistoryIcon />} title="history" onClick={openhistoryPopup}/>
            </ul>
        </Popup>
        )
    }
    
function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
    closeMenuPopup,
    openPlayerHistoryPopup,
    openLanguagesPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuPopup);