import React from "react";


function FullScreenIcon(){
    return (
      <>
        <svg className="normal-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
          <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#f2f2f2"/>
              <stop offset="1" stopColor="#bfbfbf"/>
            </linearGradient>
            <filter id="VectorFullScreenIconNormalState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1875" transform="translate(-1504 -20)">
            <rect id="Rectangle_1098" width="24" height="24" transform="translate(1510 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1504, 20)" filter="url(#VectorFullScreenIconNormalState)">
              <path id="Vector-2" d="M23.726,24l-.031,0-5.462-.644a.288.288,0,0,1-.139-.059.283.283,0,0,1-.086-.123A.267.267,0,0,1,18,23.022a.277.277,0,0,1,.073-.132l1.5-1.5L15.01,16.836a.272.272,0,0,1,0-.385l1.445-1.441a.272.272,0,0,1,.385,0L21.4,19.564l1.49-1.489a.27.27,0,0,1,.192-.08.273.273,0,0,1,.272.24L24,23.694a.272.272,0,0,1-.068.214A.275.275,0,0,1,23.726,24ZM.272,24a.272.272,0,0,1-.2-.091A.276.276,0,0,1,0,23.69l.645-5.457a.261.261,0,0,1,.059-.139.273.273,0,0,1,.123-.086.28.28,0,0,1,.089-.014A.294.294,0,0,1,.978,18a.277.277,0,0,1,.132.073L2.6,19.564,7.159,15.01a.272.272,0,0,1,.385,0l1.445,1.445a.272.272,0,0,1,0,.385L4.431,21.393l1.5,1.493a.273.273,0,0,1-.16.463l-5.462.644ZM16.649,9.069a.273.273,0,0,1-.193-.079L15.01,7.544a.272.272,0,0,1,0-.385l4.559-4.55-1.5-1.5a.273.273,0,0,1,.16-.463L23.694.005l.033,0a.272.272,0,0,1,.2.091A.276.276,0,0,1,24,.309l-.645,5.457a.274.274,0,0,1-.058.139.285.285,0,0,1-.124.086.257.257,0,0,1-.087.015A.268.268,0,0,1,23.021,6a.277.277,0,0,1-.132-.073L21.4,4.436,16.84,8.991A.269.269,0,0,1,16.649,9.069Zm-9.3,0a.269.269,0,0,1-.192-.079L2.6,4.436,1.11,5.925a.27.27,0,0,1-.192.08.273.273,0,0,1-.272-.24L0,.305A.271.271,0,0,1,.07.091.275.275,0,0,1,.274,0L.305,0,5.767.647a.261.261,0,0,1,.139.059.273.273,0,0,1,.086.123A.265.265,0,0,1,6,.978a.285.285,0,0,1-.072.132l-1.5,1.5L8.989,7.163a.272.272,0,0,1,0,.385L7.544,8.991A.273.273,0,0,1,7.351,9.069Z" transform="translate(6 4)" fill="url(#linear-gradient)"/>
            </g>
          </g>
        </svg>
        <svg className="hover-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
          <defs>
            <filter id="VectorFullScreenIconHoverState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1875" transform="translate(-1504 -20)">
            <rect id="Rectangle_1098" width="24" height="24" transform="translate(1510 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1504, 20)" filter="url(#VectorFullScreenIconHoverState)">
              <path id="Vector-2" d="M23.726,24l-.031,0-5.462-.644a.288.288,0,0,1-.139-.059.283.283,0,0,1-.086-.123A.267.267,0,0,1,18,23.022a.277.277,0,0,1,.073-.132l1.5-1.5L15.01,16.836a.272.272,0,0,1,0-.385l1.445-1.441a.272.272,0,0,1,.385,0L21.4,19.564l1.49-1.489a.27.27,0,0,1,.192-.08.273.273,0,0,1,.272.24L24,23.694a.272.272,0,0,1-.068.214A.275.275,0,0,1,23.726,24ZM.272,24a.272.272,0,0,1-.2-.091A.276.276,0,0,1,0,23.69l.645-5.457a.261.261,0,0,1,.059-.139.273.273,0,0,1,.123-.086.28.28,0,0,1,.089-.014A.294.294,0,0,1,.978,18a.277.277,0,0,1,.132.073L2.6,19.564,7.159,15.01a.272.272,0,0,1,.385,0l1.445,1.445a.272.272,0,0,1,0,.385L4.431,21.393l1.5,1.493a.273.273,0,0,1-.16.463l-5.462.644ZM16.649,9.069a.273.273,0,0,1-.193-.079L15.01,7.544a.272.272,0,0,1,0-.385l4.559-4.55-1.5-1.5a.273.273,0,0,1,.16-.463L23.694.005l.033,0a.272.272,0,0,1,.2.091A.276.276,0,0,1,24,.309l-.645,5.457a.274.274,0,0,1-.058.139.285.285,0,0,1-.124.086.257.257,0,0,1-.087.015A.268.268,0,0,1,23.021,6a.277.277,0,0,1-.132-.073L21.4,4.436,16.84,8.991A.269.269,0,0,1,16.649,9.069Zm-9.3,0a.269.269,0,0,1-.192-.079L2.6,4.436,1.11,5.925a.27.27,0,0,1-.192.08.273.273,0,0,1-.272-.24L0,.305A.271.271,0,0,1,.07.091.275.275,0,0,1,.274,0L.305,0,5.767.647a.261.261,0,0,1,.139.059.273.273,0,0,1,.086.123A.265.265,0,0,1,6,.978a.285.285,0,0,1-.072.132l-1.5,1.5L8.989,7.163a.272.272,0,0,1,0,.385L7.544,8.991A.273.273,0,0,1,7.351,9.069Z" transform="translate(6 4)" fill="#ffd35a"/>
            </g>
          </g>
        </svg>
        <svg className="pressed-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 36">
          <defs>
            <filter id="VectorFullScreenIconPressedState" x="0" y="0" width="36" height="36" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1875" transform="translate(-1504 -20)">
            <rect id="Rectangle_1098" width="24" height="24" transform="translate(1510 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1504, 20)" filter="url(#VectorFullScreenIconPressedState)">
              <path id="Vector-2" d="M23.726,24l-.031,0-5.462-.644a.288.288,0,0,1-.139-.059.283.283,0,0,1-.086-.123A.267.267,0,0,1,18,23.022a.277.277,0,0,1,.073-.132l1.5-1.5L15.01,16.836a.272.272,0,0,1,0-.385l1.445-1.441a.272.272,0,0,1,.385,0L21.4,19.564l1.49-1.489a.27.27,0,0,1,.192-.08.273.273,0,0,1,.272.24L24,23.694a.272.272,0,0,1-.068.214A.275.275,0,0,1,23.726,24ZM.272,24a.272.272,0,0,1-.2-.091A.276.276,0,0,1,0,23.69l.645-5.457a.261.261,0,0,1,.059-.139.273.273,0,0,1,.123-.086.28.28,0,0,1,.089-.014A.294.294,0,0,1,.978,18a.277.277,0,0,1,.132.073L2.6,19.564,7.159,15.01a.272.272,0,0,1,.385,0l1.445,1.445a.272.272,0,0,1,0,.385L4.431,21.393l1.5,1.493a.273.273,0,0,1-.16.463l-5.462.644ZM16.649,9.069a.273.273,0,0,1-.193-.079L15.01,7.544a.272.272,0,0,1,0-.385l4.559-4.55-1.5-1.5a.273.273,0,0,1,.16-.463L23.694.005l.033,0a.272.272,0,0,1,.2.091A.276.276,0,0,1,24,.309l-.645,5.457a.274.274,0,0,1-.058.139.285.285,0,0,1-.124.086.257.257,0,0,1-.087.015A.268.268,0,0,1,23.021,6a.277.277,0,0,1-.132-.073L21.4,4.436,16.84,8.991A.269.269,0,0,1,16.649,9.069Zm-9.3,0a.269.269,0,0,1-.192-.079L2.6,4.436,1.11,5.925a.27.27,0,0,1-.192.08.273.273,0,0,1-.272-.24L0,.305A.271.271,0,0,1,.07.091.275.275,0,0,1,.274,0L.305,0,5.767.647a.261.261,0,0,1,.139.059.273.273,0,0,1,.086.123A.265.265,0,0,1,6,.978a.285.285,0,0,1-.072.132l-1.5,1.5L8.989,7.163a.272.272,0,0,1,0,.385L7.544,8.991A.273.273,0,0,1,7.351,9.069Z" transform="translate(6 4)" fill="#fc0"/>
            </g>
          </g>
        </svg>
      </>
    )
}
export default FullScreenIcon