import React, { useEffect } from "react";
import Category from "./Category";
import {connect} from "react-redux";
import {setCategory} from "@actions/categoryActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

function Categories({categories, setCategory, selectedCategory}) {

    useEffect(() => {
        if (categories.length) {
            if (selectedCategory && categories.findIndex(item => item.id === selectedCategory.id) > -1) {
                setCategory(selectedCategory);
            } else {
                setCategory(categories[0]);
            }
        }
    }, [categories])

    return (
        <div className="nav-swiper">
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={28}
                modules={[]}
                className="menuUl"
            >
                {
                    categories.map(item => ({ ...item })).map((el) => (
                        <SwiperSlide key={el.id}>
                            <Category item={el} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <div className="nav-swiper-bg"></div>
        </div>
        
    )
}

function mapStateToProps(state) {
    return {
        categories: state.categories,
        selectedCategory: state.selectedCategory
    }
}

const mapDispatchToProps = {
    setCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories);