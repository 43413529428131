

import React from "react"
export default function Portuguese(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="_x32_9_x2C__Portugal_x2C__country_x2C__national_x2C__flag_x2C__world_flag" transform="translate(1623 236)">
      <g id="XMLID_684_" transform="translate(16 76)">
        <path id="XMLID_509_" d="M160.444,76h25.778v32H160.444L156,92Z" transform="translate(-143.556 -76)" fill="#d80027"/>
        <path id="XMLID_508_" d="M16,76H32.889v32H16Z" transform="translate(-16 -76)" fill="#6da544"/>
        <circle id="XMLID_507_" cx="5.333" cy="5.333" r="5.333" transform="translate(11.556 10.667)" fill="#ffd654"/>
        <path id="XMLID_506_" d="M178.667,232.222A2.667,2.667,0,0,1,176,229.556V226h5.333v3.556A2.667,2.667,0,0,1,178.667,232.222Z" transform="translate(-161.778 -212.667)" fill="#d80027"/>
        <path id="XMLID_505_" d="M196.889,248.667a.889.889,0,0,1-.889-.889V246h1.778v1.778A.889.889,0,0,1,196.889,248.667Z" transform="translate(-180 -230.889)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>
    )
}
