import {call, put, takeLatest} from "redux-saga/effects";
import {GetResource, getTokenByUrl} from "../../utils/HttpUtil";
import {
    SET_CATEGORIES,
    REQUEST_CATEGORIES,
    SET_ALL_GAMES
} from "@actions/actionTypes";
import {onError, safe} from "./handlerError";

function* fetchCategories({lang}) {
    const data = yield call(GetResource, `/Games?lang=${lang}&token=${getTokenByUrl()}`);
    yield put({type: SET_ALL_GAMES, games: data.games});
    yield put({type: SET_CATEGORIES, categories: data.categories});
}

export default function* categoriesSaga() {
    yield takeLatest(REQUEST_CATEGORIES, safe(onError, fetchCategories));
}