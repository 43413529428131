import initialStore from "../initialStore";
import {
    OPEN_GAME,
    CLOSE_GAME,
    SET_ALL_GAMES
} from "@actions/actionTypes";
import {
    getParamByUrl
} from "../../utils/HttpUtil";

export default function selectedGameReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case OPEN_GAME:
            return action.game;
        case SET_ALL_GAMES:
            const opennigGameId = getParamByUrl("gameId") || sessionStorage.getItem('gameId');
            if (opennigGameId) {
                const game = action.games.find(game => game.id == opennigGameId);
                if (game) {
                    return game;
                }
            }
            return state;
        case CLOSE_GAME:
            return initialStore.emptyObj;
        default:
            return state;
    }
}