import React from "react"
export default function Bulgarian(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 32 32">
  <defs>
    <clipPath id="clip-path">
      <circle id="Ellipse_1752" data-name="Ellipse 1752" cx="16" cy="16" r="16" transform="translate(1643 312)" fill="#fff" stroke="#707070" strokeWidth="1"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_110" data-name="Mask Group 110" transform="translate(-1643 -312)" clipPath="url(#clip-path)">
    <g id="bulgaria" transform="translate(1643 312)">
      <rect id="Rectangle_1867" data-name="Rectangle 1867" width="32" height="31.999" transform="translate(0 0)" fill="#496e2d"/>
      <rect id="Rectangle_1868" data-name="Rectangle 1868" width="32" height="10.666" fill="#f0f0f0"/>
      <rect id="Rectangle_1869" data-name="Rectangle 1869" width="32" height="10.666" transform="translate(0 21.334)" fill="#d80027"/>
    </g>
  </g>
</svg>
    )
}