import React from "react";


function ExitFullScreenIcon() {
    return (
      <>
        <svg className="normal-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 35.999">
          <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
              <stop offset="0" stopColor="#f2f2f2"/>
              <stop offset="1" stopColor="#bfbfbf"/>
            </linearGradient>
            <filter id="VectorExitFullScreenIconNormalState" x="0" y="0" width="36" height="35.999" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1875" transform="translate(-1504 -20)">
            <rect id="Rectangle_1098" width="24" height="24" transform="translate(1510 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1504, 20)" filter="url(#VectorExitFullScreenIconNormalState)">
              <path id="Vector-2" d="M22.281,24a.27.27,0,0,1-.193-.079L17.53,19.367l-1.49,1.49a.268.268,0,0,1-.191.08.274.274,0,0,1-.273-.24l-.644-5.46A.27.27,0,0,1,15,15.022a.274.274,0,0,1,.2-.092l.031,0,5.462.644a.277.277,0,0,1,.138.058.274.274,0,0,1,.022.4l-1.5,1.5,4.558,4.557a.272.272,0,0,1,0,.385L22.474,23.92A.27.27,0,0,1,22.281,24ZM1.717,24a.273.273,0,0,1-.193-.078L.079,22.472a.274.274,0,0,1,0-.385l4.558-4.554-1.5-1.493a.273.273,0,0,1,.16-.463l5.462-.644.032,0a.273.273,0,0,1,.272.305l-.644,5.458a.272.272,0,0,1-.182.225.279.279,0,0,1-.088.014.271.271,0,0,1-.062-.007.266.266,0,0,1-.131-.072l-1.49-1.49L1.91,23.918A.273.273,0,0,1,1.717,24ZM8.8,9.069l-.033,0L3.3,8.423a.271.271,0,0,1-.139-.059.279.279,0,0,1-.086-.123.286.286,0,0,1-.008-.15.275.275,0,0,1,.073-.131l1.5-1.5L.079,1.906a.274.274,0,0,1,0-.385L1.525.079a.274.274,0,0,1,.385,0L6.468,4.633l1.49-1.49a.268.268,0,0,1,.191-.08.274.274,0,0,1,.273.24l.644,5.46A.27.27,0,0,1,9,8.978.273.273,0,0,1,8.8,9.069Zm6.407,0a.273.273,0,0,1-.271-.305l.644-5.457a.27.27,0,0,1,.059-.139.274.274,0,0,1,.213-.1.267.267,0,0,1,.06.007.275.275,0,0,1,.131.073l1.489,1.49L22.089.083a.274.274,0,0,1,.385,0l1.445,1.445a.272.272,0,0,1,0,.385l-4.558,4.55,1.5,1.5a.273.273,0,0,1-.16.464l-5.462.644Z" transform="translate(6 4)" fill="url(#linear-gradient)"/>
            </g>
          </g>
        </svg>
        <svg className="hover-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 35.999">
          <defs>
            <filter id="VectorExitFullScreenIconHoverState" x="0" y="0" width="36" height="35.999" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1875" transform="translate(-1504 -20)">
            <rect id="Rectangle_1098" width="24" height="24" transform="translate(1510 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1504, 20)" filter="url(#VectorExitFullScreenIconHoverState)">
              <path id="Vector-2" d="M22.281,24a.27.27,0,0,1-.193-.079L17.53,19.367l-1.49,1.49a.268.268,0,0,1-.191.08.274.274,0,0,1-.273-.24l-.644-5.46A.27.27,0,0,1,15,15.022a.274.274,0,0,1,.2-.092l.031,0,5.462.644a.277.277,0,0,1,.138.058.274.274,0,0,1,.022.4l-1.5,1.5,4.558,4.557a.272.272,0,0,1,0,.385L22.474,23.92A.27.27,0,0,1,22.281,24ZM1.717,24a.273.273,0,0,1-.193-.078L.079,22.472a.274.274,0,0,1,0-.385l4.558-4.554-1.5-1.493a.273.273,0,0,1,.16-.463l5.462-.644.032,0a.273.273,0,0,1,.272.305l-.644,5.458a.272.272,0,0,1-.182.225.279.279,0,0,1-.088.014.271.271,0,0,1-.062-.007.266.266,0,0,1-.131-.072l-1.49-1.49L1.91,23.918A.273.273,0,0,1,1.717,24ZM8.8,9.069l-.033,0L3.3,8.423a.271.271,0,0,1-.139-.059.279.279,0,0,1-.086-.123.286.286,0,0,1-.008-.15.275.275,0,0,1,.073-.131l1.5-1.5L.079,1.906a.274.274,0,0,1,0-.385L1.525.079a.274.274,0,0,1,.385,0L6.468,4.633l1.49-1.49a.268.268,0,0,1,.191-.08.274.274,0,0,1,.273.24l.644,5.46A.27.27,0,0,1,9,8.978.273.273,0,0,1,8.8,9.069Zm6.407,0a.273.273,0,0,1-.271-.305l.644-5.457a.27.27,0,0,1,.059-.139.274.274,0,0,1,.213-.1.267.267,0,0,1,.06.007.275.275,0,0,1,.131.073l1.489,1.49L22.089.083a.274.274,0,0,1,.385,0l1.445,1.445a.272.272,0,0,1,0,.385l-4.558,4.55,1.5,1.5a.273.273,0,0,1-.16.464l-5.462.644Z" transform="translate(6 4)" fill="#ffd35a"/>
            </g>
          </g>
        </svg>
        <svg className="pressed-state" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 36 35.999">
          <defs>
            <filter id="VectorExitFullScreenIconPressedState" x="0" y="0" width="36" height="35.999" filterUnits="userSpaceOnUse">
              <feOffset dy="2" input="SourceAlpha"/>
              <feGaussianBlur stdDeviation="2" result="blur"/>
              <feFlood/>
              <feComposite operator="in" in2="blur"/>
              <feComposite in="SourceGraphic"/>
            </filter>
          </defs>
          <g id="Group_1875" transform="translate(-1504 -20)">
            <rect id="Rectangle_1098" width="24" height="24" transform="translate(1510 24)" fill="none"/>
            <g transform="matrix(1, 0, 0, 1, 1504, 20)" filter="url(#VectorExitFullScreenIconPressedState)">
              <path id="Vector-2" d="M22.281,24a.27.27,0,0,1-.193-.079L17.53,19.367l-1.49,1.49a.268.268,0,0,1-.191.08.274.274,0,0,1-.273-.24l-.644-5.46A.27.27,0,0,1,15,15.022a.274.274,0,0,1,.2-.092l.031,0,5.462.644a.277.277,0,0,1,.138.058.274.274,0,0,1,.022.4l-1.5,1.5,4.558,4.557a.272.272,0,0,1,0,.385L22.474,23.92A.27.27,0,0,1,22.281,24ZM1.717,24a.273.273,0,0,1-.193-.078L.079,22.472a.274.274,0,0,1,0-.385l4.558-4.554-1.5-1.493a.273.273,0,0,1,.16-.463l5.462-.644.032,0a.273.273,0,0,1,.272.305l-.644,5.458a.272.272,0,0,1-.182.225.279.279,0,0,1-.088.014.271.271,0,0,1-.062-.007.266.266,0,0,1-.131-.072l-1.49-1.49L1.91,23.918A.273.273,0,0,1,1.717,24ZM8.8,9.069l-.033,0L3.3,8.423a.271.271,0,0,1-.139-.059.279.279,0,0,1-.086-.123.286.286,0,0,1-.008-.15.275.275,0,0,1,.073-.131l1.5-1.5L.079,1.906a.274.274,0,0,1,0-.385L1.525.079a.274.274,0,0,1,.385,0L6.468,4.633l1.49-1.49a.268.268,0,0,1,.191-.08.274.274,0,0,1,.273.24l.644,5.46A.27.27,0,0,1,9,8.978.273.273,0,0,1,8.8,9.069Zm6.407,0a.273.273,0,0,1-.271-.305l.644-5.457a.27.27,0,0,1,.059-.139.274.274,0,0,1,.213-.1.267.267,0,0,1,.06.007.275.275,0,0,1,.131.073l1.489,1.49L22.089.083a.274.274,0,0,1,.385,0l1.445,1.445a.272.272,0,0,1,0,.385l-4.558,4.55,1.5,1.5a.273.273,0,0,1-.16.464l-5.462.644Z" transform="translate(6 4)" fill="#fc0"/>
            </g>
          </g>
        </svg>
      </>
    )
}
export default ExitFullScreenIcon