import initialStore from "../initialStore";
import {
    SET_TABLES_INFO
} from "@actions/actionTypes";

export default function tablesInfoReducer(state = initialStore.tablesInfo, action) {
    switch (action.type) {
        case SET_TABLES_INFO:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}