import React from "react";
import { FormattedMessage } from "react-intl";

 function ListItem({icon, title, onClick}) {

    return (
        <li className="list-item" onClick={onClick}>
            <div className="list-item-icon">
                {icon}
            </div>
            <div className="list-item-title">
                <FormattedMessage id={title} />
            </div>
        </li>
    )
}

export default ListItem;