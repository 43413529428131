import {DefaultLanguage} from "@constants/Enums";

const store = {
    settings: {
        lang: DefaultLanguage,
        isBigGrid: true
    },
    globalInfo: {
        isConnectedSocket: false
    },
    emptyObj: {},
    emptyArr: [],
    selectedCategory: {},
    categories: [],
    tablesInfo: {
        countOfTables: 0,
        countOfPlayers: 0
    },
    playerHistory: {
        interval: 180,
        roundsPlayer: 50,
        win: 20000,
        loss: 30000
    },
    popups: {
        isShowPlayerHostory: false,
        isShowLanguages: false,
        isShowMenu: false,
        isShowHistory: false,
        isShowHistoryPeriod: false
    },
    historyPeriod: 0
}

export default store;